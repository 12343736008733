/* import __COLOCATED_TEMPLATE__ from './copy-link-cta.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { captureException } from 'embercom/lib/sentry';
import ajax from 'embercom/lib/ajax';
import Clipboard from 'clipboard';
import { modifier } from 'ember-modifier';
import type InvitedAdmin from 'embercom/models/invited-admin';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

interface Arguments {
  place?: string;
}

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

export default class CopyLinkCta extends Component<Signature> {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare intercomEventService: $TSFixMe;

  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare clipboardService: any;

  @tracked showLinkCopiedText = false;
  @tracked showLiveLinkActions = false;
  @tracked genericLinkDisabled = false;
  @tracked invite: InvitedAdmin | null = null;
  clipboard?: Clipboard;

  ctaContainer = modifier(
    (_: Element) => {
      this.onRender();
    },
    { eager: false },
  );

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.getLink();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'generic_link',
      place: args.place || 'invite-page',
    });
  }

  @action
  onRender() {
    let copyLinkButton = document.querySelector('#copy-btn');
    if (copyLinkButton) {
      if (this.clipboard) {
        try {
          this.clipboard?.destroy();
        } catch (e) {
          captureException(e, {
            fingerprint: ['clipboard.destroy', 'copy-link-cta'],
          });
        }
      }
      this.clipboard = new Clipboard(copyLinkButton);
      let onCopySuccess = () => {
        this.notificationsService.removeNotification('invite-by-sharing-link-banner-success');
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'onboarding.home.steps.invite-teammates.quick-invite-modal.invite-by-sharing-link-banner-success',
          ),
          2000,
          'invite-by-sharing-link-banner-success',
        );
        this.showLinkCopiedText = true;

        setTimeout(() => {
          this.showLinkCopiedText = false;
        }, 4000);
        this.intercomEventService.trackAnalyticsEvent({
          action: 'success_copy_link',
          object: 'generic_link',
          place: this.args.place || 'invite-page',
        });
      };

      let onCopyError = (trigger: any) => {
        // We show error for the user only if the problem is with the clipboard api, not the text
        if (trigger.text) {
          this.notificationsService.removeNotification('invite-by-sharing-link-banner-error');
          this.notificationsService.notifyWarning(
            this.intl.t(
              'onboarding.home.steps.invite-teammates.quick-invite-modal.invite-by-sharing-link-banner-error',
            ),
            2000,
            'invite-by-sharing-link-banner-error',
          );
          this.intercomEventService.trackAnalyticsEvent({
            action: 'failed_copy_link',
            object: 'generic_link',
            place: this.args.place || 'invite-page',
          });
        }
      };

      this.clipboard.on('success', onCopySuccess);
      this.clipboard.on('error', onCopyError);
    }
    this.setLinkInButton();
  }

  willDestroy() {
    super.willDestroy();
    try {
      this.clipboard?.destroy();
    } catch (e) {
      captureException(e, {
        fingerprint: ['clipboard.destroy', 'copy-link-cta'],
      });
    }
  }

  get app() {
    return this.appService.app;
  }

  get emailDomain() {
    return this.app.currentAdmin.email.split('@')[1];
  }

  get fullPermissionAccessMessage() {
    return this.intl.t(
      'onboarding.home.steps.invite-teammates.quick-invite-modal.full-permission-access-message',
    );
  }

  get inviteBySharingLinkMessage() {
    return this.intl.t(
      'onboarding.home.steps.invite-teammates.quick-invite-modal.invite-by-sharing-link-message',
      {
        emailDomain: this.emailDomain,
        htmlSafe: true,
      },
    );
  }

  get groupList() {
    return [
      {
        items: [
          {
            text: this.intl.t(
              'onboarding.home.steps.invite-teammates.quick-invite-modal.deactivate-link',
            ),
            icon: 'delete',
            onSelectItem: async () => {
              await this.deactivateLinkTask.perform();
            },
          },
          {
            text: this.intl.t(
              'onboarding.home.steps.invite-teammates.quick-invite-modal.refresh-link',
            ),
            icon: 'sync',
            onSelectItem: async () => {
              await this.refreshLinkTask.perform();
            },
          },
        ],
      },
    ];
  }

  get anyLiveLinkActionsRunning() {
    return (
      this.deactivateLinkTask.isRunning ||
      this.refreshLinkTask.isRunning ||
      this.enableLinkTask.isRunning
    );
  }

  get deactivateLinkTask() {
    return taskFor(this.deactivateLink);
  }

  @dropTask
  *deactivateLink() {
    if (!this.invite) {
      return;
    }
    yield ajax({
      url: '/ember/invites/deactivate',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        type: 'generic-link',
        token: this.invite.token,
      }),
    });
    this.genericLinkDisabled = true;
    this.showLiveLinkActions = false;

    this.notificationsService.removeNotification(
      'invite-by-sharing-link-banner-success-deactivating-link',
    );
    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'onboarding.home.steps.invite-teammates.quick-invite-modal.invite-by-sharing-link-banner-success-deactivating-link',
      ),
      2000,
      'invite-by-sharing-link-banner-success-deactivating-link',
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'pause_link',
      object: 'generic_link',
      place: this.args.place || 'invite-page',
      invite_id: this.invite?.id,
    });
  }

  @action
  async getLink() {
    let invites = await ajax({
      url: '/ember/invites',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
        type: 'generic-link',
      },
    });
    if (Array.isArray(invites) && invites.length) {
      this.invite = invites[0];
      if (!this.invite?.active) {
        this.genericLinkDisabled = true;
      } else {
        this.showLiveLinkActions = true;
      }
    }
  }

  @action
  setLinkInButton() {
    let copyLinkButton = document.querySelector('#copy-btn');
    if (copyLinkButton) {
      copyLinkButton.setAttribute('data-clipboard-text', this.linkString);
    }
  }

  get refreshLinkTask() {
    return taskFor(this.refreshLink);
  }

  @dropTask
  *refreshLink() {
    if (!this.invite) {
      return;
    }
    this.invite = yield ajax({
      url: '/ember/invites/extend_link',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        token: this.invite.token,
      }),
    });
    this.notificationsService.removeNotification(
      'invite-by-sharing-link-banner-success-refreshing-link',
    );
    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'onboarding.home.steps.invite-teammates.quick-invite-modal.invite-by-sharing-link-banner-success-refreshing-link',
      ),
      2000,
      'invite-by-sharing-link-banner-success-refreshing-link',
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'refresh_link',
      object: 'generic_link',
      place: this.args.place || 'invite-page',
      invite_id: this.invite?.id,
    });
  }

  get enableLinkTask() {
    return taskFor(this.enableLink);
  }

  @dropTask
  *enableLink() {
    this.genericLinkDisabled = false;
    yield this.refreshLinkTask.perform();
    this.showLiveLinkActions = true;
  }

  async createNewGenericInviteLink(): Promise<InvitedAdmin> {
    return await ajax({
      url: '/ember/invites/create',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        type: 'generic-link',
      }),
    });
  }

  @dropTask
  *findOrCreateInvite() {
    if (!this.invite) {
      this.invite = yield this.createNewGenericInviteLink();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'create_link',
        object: 'generic_link',
        place: this.args.place || 'invite-page',
        invite_id: this.invite?.id,
      });
      // We have to copy after the api returns ( 1 second should be more than enough )
      // This is done due to a restriction on the lib we use where it cannot copy in an async function
      // https://github.com/zenorocha/clipboard.js/issues/639
      setTimeout(function () {
        let copyBtnElement = document.getElementById('copy-btn');
        if (copyBtnElement) {
          copyBtnElement.click();
        }
      }, 1000);
    }
    if (this.invite?.active) {
      this.showLiveLinkActions = true;
    }
  }

  get findOrCreateInviteTask() {
    return taskFor(this.findOrCreateInvite);
  }

  get linkString() {
    if (!this.invite) {
      return '';
    }

    let protocol = window.location.protocol;
    let host = window.location.host;
    return `${protocol}//${host}/a/invite?token=${this.invite.token}`;
  }

  get daysToExpire() {
    if (!this.invite?.expired_at) {
      return 3;
    }
    let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let firstDate = Date.parse(this.invite?.expired_at);
    let secondDate = new Date().getTime();
    return Math.floor(Math.abs((firstDate - secondDate) / oneDay));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::QuickInviteUsingLink::CopyLinkCta': typeof CopyLinkCta;
    'onboarding/guide-library/quick-invite-using-link/copy-link-cta': typeof CopyLinkCta;
  }
}
