/* import __COLOCATED_TEMPLATE__ from './add-fin-content-button.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { EntityType } from 'embercom/models/data/entity-types';
import type Router from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Session from 'embercom/services/session';

import {
  ARTICLE_CONTENT,
  CUSTOM_ANSWER_CONTENT,
  TEXT_CONTENT,
  FILE_CONTENT,
  EXTERNAL_CONTENT,
} from 'embercom/lib/ai-content-library/constants';

interface Signature {
  Element: HTMLButtonElement;
  Args: AddFinContentButtonArgs;
}

export interface AddFinContentButtonArgs {
  reloadModel: () => void;
}

export default class AddFinContentButton extends Component<Signature> {
  @service declare router: Router;
  @service declare session: Session;
  @service declare appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;

  @tracked showFinContentModal = false;
  @tracked showFileUploadModal = false;
  @tracked showUrlSourceModal = false;

  externalContentEntityType: EntityType = EntityType.ExternalContent;
  snippetEntityType: EntityType = EntityType.ContentSnippet;
  articleEntityType: EntityType = EntityType.ArticleContent;
  fileEntityType: EntityType = EntityType.FileSourceContent;

  get app() {
    return this.appService.app;
  }

  get templates() {
    return [ARTICLE_CONTENT, CUSTOM_ANSWER_CONTENT, TEXT_CONTENT, FILE_CONTENT, EXTERNAL_CONTENT];
  }

  @action openFinContentModal(): void {
    this.showFinContentModal = true;
    this.trackAnalyticsEvent('clicked', 'add_content_button');
  }

  @action handleModalBackBtnClick(): void {
    this.showFinContentModal = true;
    this.showFileUploadModal = false;
    this.showUrlSourceModal = false;
  }

  @action openFileUploadModal(): void {
    this.showFinContentModal = false;
    this.showFileUploadModal = true;
    this.trackAnalyticsEvent('clicked', 'new_file_content_card');
  }

  @action openUrlSourceModal(): void {
    this.showFinContentModal = false;
    this.showUrlSourceModal = true;
    this.trackAnalyticsEvent('clicked', 'new_url_content_card');
  }

  @action openNewArticleLink(): void {
    let url = this.router.urlFor('apps.app.articles.articles.new', this.session.workspace);
    this.trackAnalyticsEvent('clicked', 'new_article_content_card');
    safeWindowOpen(url, '_blank');
  }

  @action openNewSnippetLink(): void {
    let baseUrl = this.router.urlFor(
      'apps.app.automation.resolution-bot.fin-content',
      this.session.workspace,
    );
    let url = `${baseUrl}?createNewSnippet=true`;
    this.trackAnalyticsEvent('clicked', 'new_text_content_card');
    safeWindowOpen(url, '_blank');
  }

  @action openCustomAnswerLink(): void {
    let url = this.router.urlFor(
      'apps.app.fin-ai-agent.custom-answers.new',
      this.session.workspace,
    );
    this.trackAnalyticsEvent('clicked', 'new_custom_answer_content_card');
    safeWindowOpen(url, '_blank');
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: 'overview_optimize',
      section: 'ai_chatbot',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Optimize::AddFinContentButton': typeof AddFinContentButton;
  }
}
