/* import __COLOCATED_TEMPLATE__ from './sms-keyword-response-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SmsKeywordResponseButtons extends Component {
  @service appService;
  @service router;
  @service notificationsService;

  get app() {
    return this.appService.app;
  }

  @action
  async save() {
    try {
      await this.args.keywordResponse.save();
      this.notificationsService.notifyConfirmation(
        'Your keyword response has been successfully saved.',
      );
      return this.router.transitionTo('apps.app.outbound.settings.sms');
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
    }
  }

  @action
  cancel() {
    this.args.keywordResponse.rollbackAttributes();
    return this.router.transitionTo('apps.app.outbound.settings.sms');
  }
}
