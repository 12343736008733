/* import __COLOCATED_TEMPLATE__ from './characters-counter.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { SegmentedMessage } from 'sms-segments-calculator';

export default class SmsSegmentInfoComponent extends Component {
  get textLength() {
    return new SegmentedMessage(this.args.text).numberOfCharacters;
  }

  get charactersRemaining() {
    if (this.args.text) {
      let length = this.args.maxLength - this.textLength;
      if (length < 0) {
        length = 0;
      }
      return length;
    } else {
      return this.args.maxLength;
    }
  }
}
