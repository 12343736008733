/* import __COLOCATED_TEMPLATE__ from './when-fin-should-be-active.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { action } from '@ember/object';
// @ts-ignore
import { inject as service } from '@ember/service';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
}

export default class WhenFinShouldBeActive extends Component<Args> {
  @service declare appService: $TSFixMe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get ruleset() {
    return this.args.ruleset;
  }

  @action
  async updateScheduling() {
    let scheduled_activation = this.ruleset.scheduledActivation
      ? {
          change_at: this.ruleset.scheduledActivation.changeAt,
          timezone_type: this.ruleset.scheduledActivation.timezoneType,
        }
      : null;
    let scheduled_deactivation = this.ruleset.scheduledDeactivation
      ? {
          change_at: this.ruleset.scheduledDeactivation.changeAt,
          timezone_type: this.ruleset.scheduledDeactivation.timezoneType,
        }
      : null;

    let matching_timetable = this.ruleset.matchingTimetable
      ? {
          timezone_type: this.ruleset.matchingTimetable.timezoneType,
          timetable_type: this.ruleset.matchingTimetable.timetableType,
          time_intervals: this.ruleset.matchingTimetable.timeIntervals.serialize(),
        }
      : null;

    let scheduling = {
      matching_timetable,
      scheduled_activation,
      scheduled_deactivation,
    };

    this.args.partialSave({ scheduling });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::WhenFinShouldBeActive': typeof WhenFinShouldBeActive;
  }
}
