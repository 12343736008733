/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type ContentImportService from 'embercom/services/content-import-service';
import type IntlService from 'ember-intl/services/intl';
import { FIN_SUPPORTED_LANGUAGES, type Language } from 'embercom/lib/ai-content-library/constants';
import type Store from '@ember-data/store';

export interface PreviewArgs {
  hasContentReadyForFin: boolean;
  customActionId?: any;
  disablePreview?: { isDisabled: boolean; reason: string };
  hideChannelSelection?: boolean;
  setMessengerLang?: (lang: string) => void;
}

interface DropDownOption {
  text: string;
  value: string;
}

type Channel = 'chat' | 'email';

export default class Preview extends Component<PreviewArgs> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare contentImportService: ContentImportService;
  @service declare helpCenterService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  /* eslint-disable @intercom/intercom/no-bare-strings */
  @tracked supportedLanguages: DropDownOption[] = [{ text: 'English', value: 'en' }];
  /* eslint-enable @intercom/intercom/no-bare-strings */
  @tracked languageOverride = 'en';
  @tracked declare selectedChannel: Channel;
  @tracked declare finPreviewAddress: string;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.selectedChannel = 'chat';
    if (!this.app.isTestApp) {
      this.contentImportService.subscribeToContentImportRunStatusUpdates();
      this.contentImportService.fetchContentImportSources();
      taskFor(this.initialiseSupportedLanguages).perform();

      if (this.hasContentReadyForFin) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: 'preview',
        });
      }
    }
  }

  get chatSelected() {
    return this.selectedChannel === 'chat';
  }

  get emailSelected() {
    return this.selectedChannel === 'email';
  }

  get app() {
    return this.appService.app;
  }

  get hasContentReadyForFin() {
    return (
      this.args.hasContentReadyForFin &&
      this.contentImportService?.finIngestionState?.hasIngestedContent
    );
  }

  get showLoadingState() {
    return (
      (this.contentImportService.inProgressRunExists && !this.args.hasContentReadyForFin) ||
      (!this.contentImportService?.finIngestionState?.hasIngestedContent &&
        this.args.hasContentReadyForFin)
    );
  }

  get showFailedImport() {
    return this.contentImportService.failedRuns.length > 0 && !this.args.hasContentReadyForFin;
  }

  @task({ drop: true })
  *initialiseSupportedLanguages(): TaskGenerator<void> {
    let languageSettings = yield this.store.findRecord(
      'messenger-settings/languages',
      this.appService.app.id,
    );
    let nonDefaultPermittedLocales = languageSettings.supportedLocales
      .filter((locale: any) => locale.isPermitted)
      .map((locale: any) => locale.localeId);
    let messengerSupportedLocales = [
      'en',
      languageSettings.defaultLocale,
      ...nonDefaultPermittedLocales,
    ].uniq();

    // Intersection of Fin supported locales and Messenger supported locales
    this.supportedLanguages = FIN_SUPPORTED_LANGUAGES.filter((language: Language) =>
      messengerSupportedLocales.includes(language.locale),
    ).map((language: Language) => {
      return {
        text: language.name,
        value: language.locale,
      };
    });

    this.languageOverride = this.app.locale;
  }

  get languages(): Array<DropDownOption> {
    return this.supportedLanguages.map((locale: DropDownOption) => {
      if (locale.value === this.app.locale) {
        return {
          text: this.intl.t('onboarding.preview-fin.preview-default-language', {
            language: locale.text,
          }),
          value: locale.value,
        };
      }
      return {
        text: locale.text,
        value: locale.value,
      };
    });
  }

  @action
  setLanguageOverride(language: string): void {
    this.languageOverride = language;
    this.args.setMessengerLang?.(language);
  }

  @action
  onChannelChange(value: Channel): void {
    this.selectedChannel = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Preview': typeof Preview;
  }
}
