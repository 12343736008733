/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Preview extends Component {
  @service store;
  @service appService;

  get previewConfiguration() {
    return this.store.peekRecord('messenger-settings/all', this.appService.app.id)
      .previewConfiguration;
  }
}
