/* import __COLOCATED_TEMPLATE__ from './bot-inbox-settings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class BotInboxSettings extends Component {
  @service appService;
  @service intl;

  get assignToBotInboxText() {
    if (this.appService.app.canUseFeature('operator-workflows-deferred-actions')) {
      return this.intl.t(
        'operator.settings.turn-on-bot-inbox-channel.assign-to-bot-inbox-text-deferred-actions',
        { htmlSafe: true },
      );
    } else if (this.appService.app.canUseFinExperience) {
      return this.intl.t(
        'operator.settings.turn-on-bot-inbox-channel.assign-to-bot-inbox-text-fin',
        {
          htmlSafe: true,
        },
      );
    } else {
      return this.intl.t('operator.settings.turn-on-bot-inbox-channel.assign-to-bot-inbox-text', {
        htmlSafe: true,
      });
    }
  }
}
