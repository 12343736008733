/* import __COLOCATED_TEMPLATE__ from './new-workflow-modal.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Template from 'embercom/models/template';
import {
  CUSTOM_BOT_CONFIG,
  type BotConfigTarget,
} from 'embercom/objects/operator/configuration/configuration';
import type CustomBotConfig from 'embercom/objects/operator/configuration/custom-bot';
import { assert } from '@ember/debug';
import type Store from '@ember-data/store';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

interface Signature {
  Args: Args;
  Blocks: {
    default: [
      { toggleTemplateModal: (template?: Template, showSimilarTemplates?: boolean) => void },
    ];
  };
}

interface Args {
  availableCustomBotConfigs: CustomBotConfig[];
  templateSelectorMode: string;
  disableTransitionBack?: boolean;
}

export default class NewWorkflowModal extends Component<Signature> {
  @service declare appService: any;
  @service declare router: any;
  @service declare store: Store;

  @tracked showContentCreationModal = false;
  @tracked showSimilarTemplates: boolean | undefined = false;
  @tracked objectTypeOverride: number | null = null;
  @tracked selectedTarget: BotConfigTarget | null = null;
  @tracked selectedTemplate: Template | null = null;
  @tracked selectedTemplateContentObject: any = null;
  @tracked templates: Template[] = [];

  constructor(owner: any, args: Args) {
    super(owner, args);
    taskFor(this.loadTemplates).perform();
  }

  get app() {
    return this.appService.app;
  }

  get selectableCustomBotTypes() {
    /* eslint-disable ember/no-side-effects */
    return this.args.availableCustomBotConfigs.map((botConfig: CustomBotConfig) =>
      Object.assign({}, botConfig, {
        onSelect: () => {
          let target: BotConfigTarget = botConfig.target;
          this.selectedTarget = target;
        },
      }),
    );
  }

  @task({ drop: true })
  *loadTemplates() {
    this.templates = yield this.store.findAll('template');
  }

  @action
  toggleTemplateModal(template?: Template, showSimilarTemplates?: boolean) {
    this.showContentCreationModal = !this.showContentCreationModal;
    if (template instanceof Template) {
      this.showSimilarTemplates = showSimilarTemplates;
      this.selectTemplate(template);
    }
    if (!this.showContentCreationModal) {
      this.objectTypeOverride = null;
      this.selectedTarget = null;
      this.selectedTemplate = null;
    }
  }

  @action
  selectTemplate(template: Template) {
    this.selectedTemplateContentObject = template.ruleset.rulesetLinks.firstObject.object;
    this.selectedTarget = this.selectedTemplateContentObject.target;
    this.selectedTemplate = template;
  }

  @action
  createCustomBot(
    contentType: string | null = null,
    _deliveryChannel = null,
    _audienceType = null,
    templateId = null,
  ) {
    this.showContentCreationModal = false;
    let rulesetParams: {
      app_id: string;
      object_type: string | null;
      template_id?: string;
      role_predicate_group?: object;
      object_data?: object;
      match_behavior?: number;
    } = {
      app_id: this.app.id,
      object_type: contentType,
    };

    let botConfig = this.selectedTarget && CUSTOM_BOT_CONFIG[this.selectedTarget];

    assert(
      'Either the selected contentType is not a Custom Bot or this content type needs to be implemented',
      !!botConfig,
    );

    let { matchBehavior, rolePredicateGroup, botType, trigger } = botConfig;

    if (templateId) {
      rulesetParams.template_id = templateId;
    } else {
      rulesetParams.match_behavior = matchBehavior;
      rulesetParams.role_predicate_group = rolePredicateGroup;
      rulesetParams.object_data = {
        type: botType,
        trigger_type: trigger,
      };
    }

    this.router.transitionTo('apps.app.content.new', rulesetParams);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::NewWorkflowModal': typeof NewWorkflowModal;
  }
}
