/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class NewEmailContentHeader extends Component {
  @service appService;
  @service paywallService;
  @service router;
  @service intercomEventService;

  @action
  createContent() {
    let [emailTemplateType, emailTemplateId] = this.args.selectedEmailTemplate.split(':');

    this.args.createContent(
      objectTypes.email,
      null,
      null,
      this.args.selectedMessageTemplate?.id || null,
      {
        emailTemplateType: Number(emailTemplateType),
        emailTemplateId: Number(emailTemplateId),
      },
    );
  }

  get app() {
    return this.appService.app;
  }

  async _transitionAfterPaywallCheck(destination) {
    try {
      await this.paywallService.paywall({ featureName: 'custom_email_templates' });
      this.router.transitionTo(destination);
    } catch {
      this.paywallService.handleError;
    }
  }

  @action
  createNewVisualCustomEmailTemplate() {
    this.trackAnalyticsEvent('create_visual_template');
    this._transitionAfterPaywallCheck(
      'apps.app.outbound.settings.email-templates.new-visual-template',
    );
  }

  trackAnalyticsEvent(object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
    });
  }
}
