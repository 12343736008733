/* import __COLOCATED_TEMPLATE__ from './collapsible-list.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import platform from 'embercom/lib/browser-platform';
import { NO_TRIGGER_TARGET } from 'embercom/lib/operator/custom-bots/constants';

export default class OperatorCustomBotsCollapsibleListComponent extends Component {
  @service appService;
  @service notificationsService;
  @service store;
  @service intl;

  tooltipShortcut = platform.isMac ? '⌥' : 'ALT';

  statisticColumns = [
    { valuePath: 'sentCount', key: statisticKeys.receipts },
    { valuePath: 'goalPercent', key: statisticKeys.goals },
    { valuePath: 'engagedPercent', key: statisticKeys.replies },
    { valuePath: 'replyPercent', key: statisticKeys.replies },
    { valuePath: 'completedPercent', key: statisticKeys.completions },
  ];

  get app() {
    return this.appService.app;
  }

  get arrowIconClass() {
    return this.args.isOpen ? 'small-thin-down-arrow' : 'small-thin-right-arrow';
  }

  get lastPageHit() {
    return this.args.bots.length === this.args.totalCount;
  }

  get totalPages() {
    return Math.ceil(this.args.totalCount / this.args.pageSize);
  }

  get orderingType() {
    return this.isReusableWorkflow ? 'reusable' : 'priority';
  }

  get isReusableWorkflow() {
    return this.args.target === NO_TRIGGER_TARGET;
  }

  get columns() {
    return [
      {
        valuePath: 'priority',
        tooltip: this.intl.t(`operator.custom-bot.overview.order-${this.orderingType}.text`),
      },
      {
        valuePath: 'checkBox',
        type: 'checkbox',
      },
      ...this.args.columns,
    ];
  }

  get maybeEmptyStateClasses() {
    return this.args.bots.length ? '' : 'filter grayscale opacity-50';
  }

  // Temporary css fix to make sure the sticky header sticks at the correct position.
  get headerOpenClassName() {
    return 'collapsible-list__header__open net-new-flag';
  }

  @action
  async reorder(updatedPriorities, draggedWrapper) {
    // Reorders bots by updating their priorities in the store to match the priority from the DB.
    //
    // The flow is:
    //   1. Drag and drop a bot (content wrapper) into a new position,
    //   2. Set a new 'priority' on each content wrapper based on the updatedPriorities array (ensuring
    //      the correct relative order of the content wrappers, but not necessarily the correct priority
    //      **value**),
    //   3. Make the ruleset.reorder HTTP request with the previous and next content wrapper positions
    //      based on the updatedPriorities array,
    //   4. Update any content wrapper priorities in the store with the response from ruleset.reorder,
    //   5. Push all dirty content wrappers into the store to 'save' them, and ensure the priority is
    //      correct according to the response from ruleset.reorder.
    //
    // This flow is a bit abnormal because we are directly updating models in the store, and then saving
    // them, instead of just fetching the data again to update the Ember data models.
    //
    // This is required as we cannot guarantee the bots will be returned in the correct order immediately
    // after the reorder request, because the content wrapper priorities are asynchronously updated in
    // Elasticsearch.
    // We do have the correct priorities for each content wrapper returned from the ruleset.reorder HTTP
    // request (these are the values from MySQL), and so we can use this data to update the Ember data
    // models and guarantee the correct data is in the store without having to make another request.
    //
    // The tradeoff with not making the extra request is that any content wrappers that have been
    // created/updated/deleted since the last page load will not have their information updated in the
    // store (only the priority is being updated), and so these updates will not be displayed on the page
    // for the teammate. This is acceptable, as the teammate will now no longer have content wrappers
    // jumping around on the page after they are reordered. As soon as the user navigates away from the
    // page and back the new content wrappers will appear.

    try {
      // Set the priority of each content wrapper to a value that will ensure the correct relative
      // order of the content wrappers, so that the UI view is rendered correctly (i.e. sorting by
      // priority will give the correct order and so the rows in the UI don't jump around).
      // These priorities may not be the correct **value**, though.
      // The correct priority value will be set once the `ruleset.reorder` method call happens.
      this.args.bots.forEach((bot) => bot.set('priority', updatedPriorities.indexOf(bot)));

      let ruleset = await this.store.findRecord(
        'matching-system/ruleset',
        draggedWrapper.contentWrapperId,
      );

      let draggedBotIndex = updatedPriorities.indexOf(draggedWrapper);
      let previousBot = updatedPriorities[draggedBotIndex - 1];
      let nextBot = updatedPriorities[draggedBotIndex + 1];

      let response = await ruleset.reorder(previousBot, nextBot);

      // Map ruleset id to priority.
      let rulesetPriorityMapping = response.reduce((m, rulesetPriority) => {
        m[rulesetPriority.ruleset_id] = rulesetPriority.priority;
        return m;
      }, {});

      // Update the content wrappers in the store to have the correct priority
      // from the backend response, or clean them up if we don't get them in
      // the response.
      this.store.peekAll('outbound/content-wrapper').forEach((wrapper) => {
        let id = wrapper.contentWrapperId;
        if (id in rulesetPriorityMapping) {
          wrapper.set('priority', rulesetPriorityMapping[id]);
        } else {
          wrapper.rollbackAttributes();
        }
      });

      // Store the new priorities in the store.
      this.store.pushPayload({
        'outbound/content-wrapper': this.store
          .peekAll('outbound/content-wrapper')
          .filter((wrapper) => wrapper.hasDirtyAttributes)
          .map((wrapper) => wrapper.toJSON({ includeId: true })),
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t(`operator.custom-bot.overview.order-${this.orderingType}.success`),
      );
    } catch {
      this.notificationsService.notifyError(
        this.intl.t(`operator.custom-bot.overview.order-${this.orderingType}.fail`),
      );

      // If there were any errors, let's rollback all the content wrappers
      // that were updated so that they aren't dirty.
      this.args.bots
        .filter((bot) => bot.hasDirtyAttributes)
        .forEach((bot) => bot.rollbackAttributes());
    }
  }
}
