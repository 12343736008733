/* import __COLOCATED_TEMPLATE__ from './select-country.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import {
  countryData,
  FILTERED_COUNTRIES_FROM_ONBOARDING,
} from 'embercom/components/outbound/sms/onboarding/country-data';
import ENV from 'embercom/config/environment';
import { REQUEST_COUNTRY_SURVEY_ID } from '../../../../models/data/sms/constants';
export default class SelectCountry extends Component {
  @service appService;

  @tracked selectedCountry;

  get countryData() {
    let unfilteredCountryData = this.args.availableCountries || countryData;
    return _.omit(unfilteredCountryData, FILTERED_COUNTRIES_FROM_ONBOARDING);
  }

  get verificationType() {
    return this.selectedCountry.isTollFree ? 'toll-free' : 'regulatory-bundle';
  }

  @action
  requestCountrySurveyLink(event) {
    event.preventDefault();
    if (ENV.environment !== 'development') {
      Intercom('startSurvey', REQUEST_COUNTRY_SURVEY_ID);
    }
  }

  @action
  updateCountry(countryCode) {
    this.selectedCountry = this.countryData[countryCode];
  }

  @action
  setParentCountryCode() {
    this.args.updateSelectedCountryCode(this.selectedCountry.code);
  }
}
