/* import __COLOCATED_TEMPLATE__ from './if-fin-cannot-resolve-the-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import AssignConversation from 'embercom/models/operator/visual-builder/step/assign-conversation';
import CloseConversation from 'embercom/models/operator/visual-builder/step/close-conversation';
import ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';
import type Step from 'embercom/models/operator/visual-builder/step';
import type IntlService from 'embercom/services/intl';
import { type FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type Profile from 'embercom/models/ai-agent/profile';
import type Store from '@ember-data/store';
import type Group from 'embercom/models/operator/visual-builder/group';
import { type ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
  channelType: ChannelType;
}

type HandoverType = 'route_to_inbox' | 'close_conversation' | null;

const ASSIGN_CONVERSATION_STEP_TYPE = 'operator/visual-builder/step/assign-conversation';
const CLOSE_CONVERSATION_STEP_TYPE = 'operator/visual-builder/step/close-conversation';
const CHAT_MESSAGE_STEP_TYPE = 'operator/visual-builder/step/chat-message';

export default class IfFinCannotResolveTheConversation extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked handoverType: HandoverType = null;
  @tracked selectedGenericTriage = this.defaultGenericTriage;
  @tracked selectedLocale = this.defaultLocale;

  @tracked assigneeId = '0';

  @tracked closeConversationLocalizations: Record<string, Group> = {};

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.setInitialHandoverType();
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get assignee() {
    let assigneeId = this.assigneeId;

    return this.appService.app.assignableAdmins.findBy('id', assigneeId);
  }

  get genericTriageLocalizations() {
    return this.behavior.genericTriageLocalizations;
  }

  get defaultGenericTriage() {
    let defaultLocalization = this.genericTriageLocalizations.find((l: $TSFixMe) => l.default);

    if (defaultLocalization) {
      return defaultLocalization;
    } else {
      let englishLocalization = this.genericTriageLocalizations.find(
        (l: $TSFixMe) => l.locale === 'en',
      );
      return englishLocalization;
    }
  }

  @computed('closeConversationLocalizations', 'selectedLocale')
  get selectedCloseConversationLocalization() {
    return this.closeConversationLocalizations[this.selectedLocale];
  }

  @computed('selectedCloseConversationLocalization.steps.@each.type')
  get chatMessagesForSelectedLocale() {
    return this.selectedCloseConversationLocalization.steps.filter(
      (step: Step) => step.type === CHAT_MESSAGE_STEP_TYPE,
    );
  }

  get invalidChatMessageLocales() {
    if (this.handoverType !== 'close_conversation') {
      return [];
    }

    let enabledLocalizations = this.genericTriageLocalizations.filter(
      (localization: $TSFixMe) => localization.enabled,
    );
    let invalidLocalizations: string[] = [];

    enabledLocalizations.forEach((localization: $TSFixMe) => {
      let steps = this.closeConversationLocalizations[localization.locale].steps;
      if (!steps.every((step: Step) => step.validations.isValid)) {
        invalidLocalizations.push(localization.name);
      }
    });
    return invalidLocalizations;
  }

  get hasInvalidChatMessage() {
    return this.invalidChatMessageLocales.length > 0;
  }

  get invalidChatMessageError() {
    return this.intl.t('operator.fin.setup.setup-and-go-live.empty-message-part-error', {
      languages: this.invalidChatMessageLocales.join(', '),
    });
  }

  get localeOptions() {
    return this.genericTriageLocalizations.map((localization: $TSFixMe) => ({
      locale_name: localization.name,
      locale_id: `(${localization.locale.toUpperCase()})`,
      value: localization.locale,
      state: localization.enabled,
      default: localization.default,
      component: 'operator/generic-triage/locale-option',
    }));
  }

  get defaultLocale() {
    let defaultLocalization = this.genericTriageLocalizations.find((l: $TSFixMe) => l.default);

    return defaultLocalization ? defaultLocalization.locale : 'en';
  }

  @action
  setHandoverType(type: HandoverType) {
    this.handoverType = type;
    if (this.handoverType === 'close_conversation') {
      this.behavior.botMode = 'bot_only';
      this.behavior.preHandoverAnswerEnabled = false;
    } else {
      this.behavior.botMode = 'looping';
    }
  }

  @action
  updateLocale(locale: string) {
    this.selectedLocale = locale;
    this.selectedGenericTriage = this.genericTriageLocalizations.findBy('locale', locale);
  }

  @action
  removeStep(step: ChatMessage) {
    if (this.chatMessagesForSelectedLocale.length === 1) {
      return;
    }
    this.selectedCloseConversationLocalization.steps.removeObject(step);
  }

  @action
  addStep() {
    let index = this.chatMessagesForSelectedLocale.length;
    let step = ChatMessage.createNewStep(this.store);
    this.selectedCloseConversationLocalization.steps.insertAt(index, step);
  }

  @action
  async updateHandover() {
    this.buildHandoverGroup();

    let generic_triage_localizations = this.genericTriageLocalizations.map((l: any) =>
      l.serialize(),
    );

    let params = {
      generic_triage_localizations,
      pre_handover_settings: {
        enabled: this.behavior.preHandoverAnswerEnabled,
      },
    };

    this.args.partialSave(params);
  }

  @action
  setAssigneeId(assigneeId: string) {
    this.assigneeId = assigneeId;
  }

  private getStep(
    genericTriageLocalization: $TSFixMe,
    stepType: string,
  ): AssignConversation | CloseConversation | undefined {
    return genericTriageLocalization.visualBuilderObject.groups.firstObject.steps.find(
      (step: AssignConversation | CloseConversation) => step.type === stepType,
    );
  }

  private setInitialHandoverType() {
    let stepType =
      this.defaultGenericTriage.visualBuilderObject.groups.firstObject.steps.lastObject?.type;

    if (stepType === CLOSE_CONVERSATION_STEP_TYPE) {
      this.handoverType = 'close_conversation';
    } else {
      this.handoverType = 'route_to_inbox';
    }

    this.setInitialAssignee();
    this.setInitialCloseConversationLocalizations();
  }

  private setInitialAssignee() {
    let assignConversationStep: $TSFixMe =
      this.getStep(this.defaultGenericTriage, ASSIGN_CONVERSATION_STEP_TYPE) ||
      AssignConversation.createNewStep(this.store);

    this.assigneeId = assignConversationStep.action?.actionData?.assignee_id;
  }

  private maybeSaveAssignmentHandoverGroup() {
    if (this.handoverType !== 'route_to_inbox') {
      return;
    }

    this.genericTriageLocalizations.forEach((localization: $TSFixMe) => {
      let assignConversationStep: $TSFixMe = AssignConversation.createNewStep(this.store);
      assignConversationStep.action.actionData.assignee_id = this.assigneeId;
      localization.visualBuilderObject.groups.firstObject.steps = [assignConversationStep];
      localization.enabled = true;
    });
  }

  private setInitialCloseConversationLocalizations() {
    this.genericTriageLocalizations.map((localization: $TSFixMe) => {
      let locale = localization.locale;

      let chatMessageSteps = localization.visualBuilderObject.groups.firstObject.steps.filter(
        (step: Step) => step.type === CHAT_MESSAGE_STEP_TYPE,
      );
      let closeConversationStep =
        this.getStep(localization, CLOSE_CONVERSATION_STEP_TYPE) ||
        CloseConversation.createNewStep(this.store);

      let group = this.store.createRecord('operator/visual-builder/group', {
        isStart: true,
      }) as Group;

      if (chatMessageSteps.length) {
        group.steps.addObjects(chatMessageSteps);
      } else {
        group.steps.addObject(ChatMessage.createNewStep(this.store));
      }
      group.steps.addObject(closeConversationStep);

      this.closeConversationLocalizations[locale] = group;
    });
  }

  private maybeSaveCloseConversationHandoverGroup() {
    if (this.handoverType !== 'close_conversation') {
      return;
    }

    this.genericTriageLocalizations.forEach((localization: $TSFixMe) => {
      localization.visualBuilderObject.groups = [
        this.closeConversationLocalizations[localization.locale],
      ];
    });
  }

  private buildHandoverGroup() {
    this.maybeSaveAssignmentHandoverGroup();
    this.maybeSaveCloseConversationHandoverGroup();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::IfFinCannotResolveTheConversation': typeof IfFinCannotResolveTheConversation;
  }
}
