/* import __COLOCATED_TEMPLATE__ from './radial-progress-indicator.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import PALETTE from '@intercom/pulse/lib/palette';

interface Args {
  strokeWidth: number;
  size: number;
  primaryColor: string;
  secondaryColor: string;
  currentStep: number;
  totalSteps: number;
}

interface Signature {
  Args: Args;
  Element: never;
}
export default class RadialProgressIndicator extends Component<Signature> {
  get cx() {
    return this.size / 2;
  }

  get strokeWidth() {
    return this.args.strokeWidth || 5;
  }

  get size() {
    return this.args.size || 36;
  }

  get cy() {
    return this.size / 2;
  }

  get radius() {
    return this.size / 2 - this.strokeWidth / 2;
  }

  get circleCircumference() {
    return 2 * Math.PI * this.radius;
  }

  get primaryColor() {
    return this.args.primaryColor || PALETTE.blue;
  }

  get secondaryColor() {
    return this.args.secondaryColor || PALETTE.gray;
  }

  get progressCircumference() {
    if (this.args.currentStep >= this.args.totalSteps) {
      return 0;
    }

    let rateOfCompletion = this.args.currentStep / this.args.totalSteps;
    let circleCircum = this.circleCircumference;

    if (rateOfCompletion === 0) {
      return circleCircum - 0.01;
    }

    return circleCircum - circleCircum * rateOfCompletion;
  }

  get strokeColor() {
    let progressCounter = this.args.currentStep;

    if (progressCounter === 0) {
      return this.secondaryColor;
    }

    return this.primaryColor;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GettingStarted::Common::RadialProgressIndicator': typeof RadialProgressIndicator;
  }
}
