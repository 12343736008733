/* import __COLOCATED_TEMPLATE__ from './configure-workflow.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type WorkflowSidedrawerService from 'embercom/services/workflow-sidedrawer-service';
import { type SetupArgs } from 'embercom/services/workflow-sidedrawer-service';
import FinWorkflowPreview from 'embercom/lib/workflows/fin-workflow-preview';
import type Store from '@ember-data/store';
import type Template from 'embercom/models/template';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type { ChannelType, HandoverType } from 'embercom/lib/workflows/fin-workflow-preview';
import type { BannerContent } from 'embercom/components/operator/fin/setup/fin-content-banner';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';

interface Args {
  previewConfiguration: $TSFixMe;
  inSidedrawer?: boolean;
  closeSidedrawer?: () => void;
  showConfetti?: () => void;
  openTemplateModal: (template?: Template, showSimilarTemplates?: boolean) => void;
  hasContentReadyForFin?: boolean;
  channelType?: ChannelType;
}

export default class ConfigureWorkflow extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare attributeService: $TSFixMe;
  @service declare store: Store;
  @service declare workflowSidedrawerService: WorkflowSidedrawerService;
  @service declare aiAgentSetupService: AiAgentSetupService;

  @tracked openSectionId = '';

  @tracked showCsatBlockAfterUpdate: boolean;
  channelType: ChannelType = this.args.channelType || 'chat';
  finWorkflowPreview: FinWorkflowPreview;
  ignoredAttributeTypesForPreview = ['message', 'conversation', 'office_hours', 'team_capacity'];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.finWorkflowPreview = new FinWorkflowPreview(owner);
    if (this.args.channelType) {
      this.finWorkflowPreview.setChannelType(this.args.channelType);
      this.setChannelType(this.args.channelType);
    }

    this.showCsatBlockAfterUpdate =
      this.finWorkflowPreview.csatBlockUpdateAfter !== undefined &&
      this.finWorkflowPreview.csatBlockUpdateAfter !== null;
  }

  get isMessengerInstalled() {
    return this.guideLibraryService.isMessengerInstalled;
  }

  get isEmailSetup() {
    return this.finWorkflowPreview.channelType === 'email';
  }

  get emailSetupValid() {
    let result = this.aiAgentSetupService.validateEmailSetup;
    return result.isValid;
  }

  get handoverType() {
    return this.finWorkflowPreview.handoverType;
  }

  get csatEnabled() {
    return this.finWorkflowPreview.csatEnabled;
  }

  get assignee() {
    return this.appService.app.assignableAdmins.findBy('id', this.assigneeId);
  }

  get assigneeId() {
    return this.finWorkflowPreview.assigneeId;
  }

  get parts() {
    return this.finWorkflowPreview.parts;
  }

  get ruleset() {
    return this.finWorkflowPreview.ruleset;
  }

  get userTypes() {
    return this.ruleset?.rolePredicateGroup?.userPredicate?.userTypes || [];
  }

  get additionalAttributes() {
    return this.attributeService.visualBotBuilderConditionalAttributes;
  }

  get bannerContent(): BannerContent {
    if (this.finWorkflowPreview.channelType === 'email') {
      return {
        heading: 'operator.fin.setup.tabs.setup-and-go-live.banner.fin-over-email.heading',
        description: 'operator.fin.setup.tabs.setup-and-go-live.banner.fin-over-email.description',
        helpLink: {
          text: 'operator.fin.setup.tabs.setup-and-go-live.banner.fin-over-email.help-link',
          url: 'https://www.intercom.com/help/en/articles/9356221-fin-ai-agent-over-email',
        },
        dismissalKey: 'discovery-banner-fin-over-email',
      };
    }
    return {
      heading: this.args.hasContentReadyForFin
        ? 'operator.fin.setup.tabs.setup-and-go-live.banner.ready-for-live.heading'
        : 'operator.fin.setup.tabs.setup-and-go-live.banner.content-empty.heading',
      description: this.args.hasContentReadyForFin
        ? 'operator.fin.setup.tabs.setup-and-go-live.banner.ready-for-live.description'
        : 'operator.fin.setup.tabs.setup-and-go-live.banner.content-empty.description',
      dismissalKey: this.args.hasContentReadyForFin
        ? `discovery-banner-fin-ready-for-live`
        : `discovery-banner-fin-content-empty`,
    };
  }

  get rulesetHasPredicates() {
    if (!this.ruleset.predicateGroup.predicates) {
      return false;
    }

    return !!this.ruleset.predicateGroup.predicates.length;
  }

  get rulesetPredicatesCount() {
    if (!this.ruleset.predicateGroup.predicates) {
      return 0;
    }

    return (
      this.ruleset.predicateGroup.predicates?.firstObject?.predicates?.length ||
      this.ruleset.predicateGroup.predicates.length
    );
  }

  @action
  setChannelType(channelType: ChannelType) {
    this.finWorkflowPreview.setChannelType(channelType);
    if (channelType === 'email') {
      this.finWorkflowPreview.setHandoverType('route_to_inbox');
    }
  }

  @action
  setHandoverType(type: HandoverType) {
    this.finWorkflowPreview.setHandoverType(type);
  }

  @action
  setAssigneeId(assigneeId: string) {
    this.finWorkflowPreview.setAssigneeId(assigneeId);
  }

  @action
  toggleCsatEnabled() {
    this.finWorkflowPreview.csatEnabled = !this.csatEnabled;

    if (!this.finWorkflowPreview.csatEnabled) {
      this.finWorkflowPreview.csatBlockUpdateAfter = undefined;
      this.showCsatBlockAfterUpdate = false;
    }
  }

  @action
  toggleRatingChange() {
    this.showCsatBlockAfterUpdate = !this.showCsatBlockAfterUpdate;

    if (!this.showCsatBlockAfterUpdate) {
      this.finWorkflowPreview.csatBlockUpdateAfter = undefined;
    }

    if (
      this.showCsatBlockAfterUpdate &&
      this.finWorkflowPreview.csatBlockUpdateAfter === undefined
    ) {
      this.finWorkflowPreview.csatBlockUpdateAfter = 0;
    }
  }

  @action
  addPart() {
    this.finWorkflowPreview.addPart();
  }

  @action
  removePart(part: any) {
    this.finWorkflowPreview.removePart(part);
  }

  @action
  onUpdatePredicates(predicates: any) {
    this.finWorkflowPreview.updatePredicates(predicates);
    this.ruleset.get('fetchAudiencePreviewTask').perform(this.ignoredAttributeTypesForPreview);
  }

  @action
  onUpdateRolePredicates(rolePredicates: any) {
    this.finWorkflowPreview.updateRolePredicates(rolePredicates);
    this.ruleset.get('fetchAudiencePreviewTask').perform(this.ignoredAttributeTypesForPreview);
  }

  @action
  setBlockUpdateAfter(e: InputEvent & { target: HTMLInputElement }) {
    let value = parseInt((e.target as HTMLInputElement).value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    this.finWorkflowPreview.csatBlockUpdateAfter = value * 3600;
  }

  @action
  async previewWorkflow() {
    await this.finWorkflowPreview.generatePreview();
    let setupParams: SetupArgs = {
      ruleset: this.ruleset,
      previewObject: this.finWorkflowPreview,
    };
    if (this.args.inSidedrawer) {
      setupParams.onEdit = this.args.closeSidedrawer;
      setupParams.onSetLive = this.args.closeSidedrawer;
    } else if (this.args.showConfetti) {
      setupParams.onSetLive = this.args.showConfetti;
    }

    this.workflowSidedrawerService.setup(setupParams);
  }

  @action
  async startWithTemplate() {
    let templates = await this.store.findAll('template');
    let firstFinTemplate = templates.findBy('isFinTemplate', true);
    let showSimilarTemplates = true;
    this.args.openTemplateModal(firstFinTemplate, showSimilarTemplates);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ConfigureWorkflow': typeof ConfigureWorkflow;
  }
}
