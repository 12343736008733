/* import __COLOCATED_TEMPLATE__ from './send-test-message-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { addEventListener } from 'ember-lifeline';
import { inject as service } from '@ember/service';
import { INBOX_ESSENTIAL_ID } from 'embercom/lib/billing';
import { dropTask } from 'ember-concurrency-decorators';

export default class SendTestMessageComponent extends Component {
  @service hostedMessengerConversationService;
  @service appService;

  hostedMessengerConversationStarted = false;
  inboxEssentialPlanId = INBOX_ESSENTIAL_ID;

  constructor() {
    super(...arguments);

    addEventListener(this, window, 'message', (event) => {
      if (event.data.key === 'hosted-messenger-conversation-started') {
        this.hostedMessengerConversationStarted = true;
        this.hostedMessengerConversationService.latestConversationId = event.data.conversationId;
      }
    });
  }

  get app() {
    return this.appService.app;
  }

  get isOnFreeWithInboxAccess() {
    return this.app.isOnFree && this.app.currentAdminHasInboxAccess;
  }

  @dropTask
  *checkCompletion() {
    if (this.hostedMessengerConversationStarted) {
      yield this.args.completionCallback();
    }
  }
}
