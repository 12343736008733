/* import __COLOCATED_TEMPLATE__ from './email.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type TabItem } from 'embercom/components/new-settings/common/standard-base';
import { tracked } from '@glimmer/tracking';
import type Brand from 'embercom/models/brand';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type CustomEmailAddress from 'embercom/models/custom-email-address';

interface Args {
  model: $TSFixMe;
  tab: string;
  changeTab: (tab: string) => void;
}

const CAN_MANAGE_MESSAGES_SETTINGS = 'can_manage_messages_settings';

export default class Email extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;

  @tracked isAddressSidebarOpen = false;
  @tracked isAddressSidebarInUpdateMode = false;
  @tracked UNVERIFIED_LIMIT = 10;

  get tabs(): Array<TabItem & { canAccess: boolean }> {
    if (this.appService.app.canUseMultiBrandEmailOpenBeta) {
      return [
        {
          label: 'new-settings.channels.email.tabs.addresses',
          value: 'addresses',
          canAccess: this.appService.app.canUseMultiBrandEmailOpenBeta,
        },
        {
          label: 'new-settings.channels.email.tabs.customisation',
          value: 'customisation',
          canAccess:
            this.args.model.canAccessProactiveSettings ||
            this.args.model.canAccessProductSettings ||
            this.args.model.canAccessWorkspaceSettings,
        },
        {
          label: 'new-settings.channels.email.tabs.advanced',
          value: 'advanced',
          canAccess: this.appService.app.canUseMultiBrandEmailOpenBeta,
        },
      ];
    } else {
      return [
        {
          label: 'new-settings.channels.email.tabs.email-forwarding',
          value: 'email-forwarding',
          canAccess: this.args.model.canAccessProactiveSettings,
        },
        {
          label: 'new-settings.channels.email.tabs.sender-email-addresses',
          value: 'sender-email-addresses',
          canAccess: true,
        },
        {
          label: 'new-settings.channels.email.tabs.domains',
          value: 'domains',
          canAccess: true,
        },
        {
          label: 'new-settings.channels.email.tabs.customisation',
          value: 'customisation',
          canAccess:
            this.args.model.canAccessProactiveSettings ||
            this.args.model.canAccessProductSettings ||
            this.args.model.canAccessWorkspaceSettings,
        },
        {
          label: 'new-settings.channels.email.tabs.advanced',
          value: 'advanced',
          canAccess: true,
        },
        {
          label: 'new-settings.channels.email.tabs.addresses',
          value: 'addresses',
          canAccess: this.appService.app.canUseMultiBrandEmailOpenBeta,
        },
      ];
    }
  }

  get isMultiBrandEmailOpenBetaEnabled() {
    return this.appService.app.canUseMultiBrandEmailOpenBeta;
  }

  get filteredTabs() {
    return this.tabs.filter((tab) => !!tab.canAccess);
  }

  get selectedTab() {
    if (this.filteredTabs.find((tab) => tab.value === this.args.tab)) {
      return this.args.tab;
    } else {
      return this.filteredTabs[0].value;
    }
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.args.tab || !this.filteredTabs.find((tab) => tab.value === this.args.tab)) {
      this.setSelectedTab(this.filteredTabs[0].value);
    }
  }

  get senderEmailAddressSettings(): SenderEmailAddressSettings[] {
    return this.senderEmailAddressSettingsLoader.value || [];
  }

  @use senderEmailAddressSettingsLoader = AsyncData<SenderEmailAddressSettings[]>(async () => {
    if (this.appService.app.canUseMultiBrandEmailOpenBeta) {
      return this.store.findAll('sender-email-address-settings');
    } else {
      return [];
    }
  });

  get brands(): Brand[] {
    return this.brandsLoader.value || [];
  }

  @use brandsLoader = AsyncData<Brand[]>(async () => {
    if (!this.appService.app.canUseMultiBrandEmailOpenBeta) {
      return [];
    }

    try {
      return this.store.findAll('brand');
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.signature.load-error'),
      });
      return [];
    }
  });

  get canSetupEmailForwarding() {
    return this.permissionsService.currentAdminCan(CAN_MANAGE_MESSAGES_SETTINGS);
  }

  get app() {
    return this.appService.app;
  }

  get customBounceSettings() {
    return this.app.customBounceSettings;
  }

  get dkimSettings() {
    return this.app.dkim_settings;
  }

  get customEmailAddresses() {
    return this.app.customEmailAddresses;
  }

  get companyEmailAddresses() {
    return this.app.companyEmailAddresses;
  }

  get totalUnverifiedEmailsLimit() {
    if (!this.app.customEmailAddresses) {
      return false;
    }
    let unverifiedEmailsCount = this.app.customEmailAddresses.filter(
      (email: CustomEmailAddress) => !email.verified,
    ).length;
    return unverifiedEmailsCount >= this.UNVERIFIED_LIMIT;
  }

  @action
  setSelectedTab(value: string): void {
    this.args.changeTab(value);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: `${value}-tab`,
      place: 'apps.app.settings.channels.email',
      section: 'ia-settings',
      context: `new-settings.channels.email.tabs.${value}`,
    });
  }

  @action
  openAddressSidebar(updatingExistingEmail: boolean) {
    this.isAddressSidebarOpen = true;
    this.isAddressSidebarInUpdateMode = updatingExistingEmail;
  }

  @action
  closeAddressSidebar() {
    this.isAddressSidebarOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email': typeof Email;
  }
}
