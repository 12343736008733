/* import __COLOCATED_TEMPLATE__ from './instructions-component-with-tech-bundle.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class InstructionsComponentWithTechBundle extends Component {
  @service appService;
  @service realTimeEventService;
  @service intl;

  @tracked confirmationCode;
  @tracked confirmationLink;
  @tracked selectedDomain;
  @tracked emailProviderOptions = [
    { value: 'google', label: this.intl.t('onboarding.home.steps.forward-emails.Google') },
    { value: 'microsoft', label: this.intl.t('onboarding.home.steps.forward-emails.Microsoft') },
    { value: 'other', label: this.intl.t('onboarding.home.steps.forward-emails.Other') },
  ];

  constructor() {
    super(...arguments);
    this.realTimeEventService.on(
      'ReceivedForwardEmailConfirmationCode',
      this,
      'fetchEmailForwardingConfirmationCode',
    );
    if (this.args.onSettingsPage) {
      this.realTimeEventService.on(
        'ReceivedForwardEmailConfirmationLink',
        this,
        'fetchEmailForwardingConfirmationLink',
      );
    }
    this.selectedDomain = this.emailProviderOptions[0].value;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.realTimeEventService.off(
      'ReceivedForwardEmailConfirmationCode',
      this,
      'fetchEmailForwardingConfirmationCode',
    );
    if (this.args.onSettingsPage) {
      this.realTimeEventService.off(
        'ReceivedForwardEmailConfirmationLink',
        this,
        'fetchEmailForwardingConfirmationLink',
      );
    }
  }

  @action
  setSelectedDomain(value) {
    if (this.selectedDomain !== value) {
      this.selectedDomain = value;
    }
  }

  @action
  handleUnsetEmail() {
    if (this.confirmationLink) {
      this.confirmationLink = '';
    }
    this.args.unsetEmail();
  }

  @action
  async fetchEmailForwardingConfirmationCode() {
    let request = {};
    request = {
      url: '/ember/forward_email_confirmation_code',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    };

    let code = await ajax(request);

    if (code) {
      this.confirmationCode = code['confirmation_code'];
    }
  }

  @action
  async fetchEmailForwardingConfirmationLink() {
    let request = {};
    if (this.args.onSettingsPage) {
      request = {
        url: '/ember/forward_email_confirmation_code',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      };
    }
    let link = await ajax(request);
    if (link) {
      this.confirmationLink = link['confirmation_code'];
    }
  }
}
