/* import __COLOCATED_TEMPLATE__ from './fin-workflows-overview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { action } from '@ember/object';
import { Column, ColumnConfigs } from 'embercom/objects/operator/configuration/custom-bot';
import type Store from '@ember-data/store';
import type WorkflowSidedrawerService from 'embercom/services/workflow-sidedrawer-service';
import type { BannerContent } from 'embercom/components/operator/fin/setup/fin-content-banner';
import { tracked } from 'tracked-built-ins';

interface Arguments {
  data: any[];
  pageSize: number;
  lastPageHit: boolean;
  totalPages: number;
  isLoading: boolean;
  loadMore: () => void;
  liveFinWorkflowsCount: number;
  openNewWorkflowSidedrawer: () => void;
  channelType: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

type TableSortState = {
  valuePath: string;
  direction: SortDirection;
};
export default class FinWorkflowsOverview extends Component<Signature> {
  @service declare store: Store;
  @service declare intl: any;
  @service declare appService: any;
  @service declare workflowSidedrawerService: WorkflowSidedrawerService;
  @tracked sortBy: TableSortState = { valuePath: '', direction: SortDirection.ASC };

  readonly columnConfigs = new ColumnConfigs();
  statisticColumns = [{ valuePath: 'sentCount', key: statisticKeys.receipts }];

  get columnKeys(): (keyof ColumnConfigs)[] {
    return ['title', 'state', 'lastUpdatedBy', 'sentCount'];
  }

  get columns() {
    return this.columnKeys.map((key) => {
      let col = new Column(this.intl, this.columnConfigs[key]);
      if (key === 'state') {
        col.isSortable = true;
      }
      return col;
    });
  }

  get sortedData() {
    let key = this.sortBy.valuePath;
    return this.args.data.sort((a: any, b: any) => {
      let aVal = a[key] || a.contents?.firstObject?.[key];
      let bVal = b[key] || b.contents?.firstObject?.[key];
      if (aVal > bVal) {
        return this.sortBy?.direction === SortDirection.ASC ? 1 : -1;
      } else if (aVal < bVal) {
        return this.sortBy?.direction === SortDirection.ASC ? -1 : 1;
      }
      return 0; // return 0 when aVal and bVal are equal
    });
  }
  get sortDirection() {
    return this.sortBy.direction;
  }
  @action
  onSortUpdate(sortBy: string) {
    let sort: TableSortState = {
      valuePath: sortBy,
      direction:
        this.sortBy.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
    };
    this.sortBy = sort;
  }

  get emailBannerContent(): BannerContent {
    return {
      heading: 'operator.fin.setup.tabs.setup-and-go-live.banner.fin-over-email.heading',
      description: 'operator.fin.setup.tabs.setup-and-go-live.banner.fin-over-email.description',
      helpLink: {
        text: 'operator.fin.setup.tabs.setup-and-go-live.banner.fin-over-email.help-link',
        url: 'https://www.intercom.com/help/en/articles/9356221-fin-ai-agent-over-email',
      },
      dismissalKey: 'discovery-banner-fin-over-email',
    };
  }

  get showContentBanner(): boolean {
    return !this.args.liveFinWorkflowsCount;
  }

  @action
  async openWorkflowInSidedrawer(rulesetId: string) {
    let ruleset = await this.store.findRecord('matching-system/ruleset', rulesetId);
    this.workflowSidedrawerService.setup({ ruleset });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::FinWorkflowsOverview': typeof FinWorkflowsOverview;
  }
}
