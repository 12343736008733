/* import __COLOCATED_TEMPLATE__ from './fin-workflow-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    launchModal: () => void;
  };
}

const FinWorkflowBanner = templateOnlyComponent<Signature>();
export default FinWorkflowBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::FinWorkflowBanner': typeof FinWorkflowBanner;
  }
}
