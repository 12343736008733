/* import __COLOCATED_TEMPLATE__ from './target-channel-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  type AdditionalSearchableData,
  allChannels,
  CHANNEL_METADATA_MAPPING,
} from 'embercom/lib/operator/custom-bots/constants';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';

interface Args {
  targetChannels: AdditionalSearchableData['targetChannels'];
  additionalSearchableData: AdditionalSearchableData | null;
  activeFilter?: string;
  filterContent: (key: string, value: any) => void;
  removeFromActiveFilter?: (key: string) => void;
  showDelete?: boolean;
}

export default class TargetChannelComponent extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked selectedChannels = this.args.targetChannels || [];

  get app() {
    return this.appService.app;
  }

  get defaultChannels() {
    return allChannels(this.appService.app.enableTwitterIntegrationBots).map((channel) => {
      let metadata = CHANNEL_METADATA_MAPPING[channel];
      let text = this.intl.t('operator.workflows.channels.type', {
        channel: metadata.value,
      });
      let isSelected = this.args.targetChannels
        ? this.args.targetChannels.includes(channel)
        : false;

      return { ...metadata, text, isSelected };
    });
  }

  get additionalSearchableData() {
    return this.args.additionalSearchableData || {};
  }

  get isOpen() {
    return this.args.activeFilter === 'target-channel';
  }

  get toolTipChannelList() {
    return this.intl.formatList(this.localizedList, {
      type: 'conjunction',
    });
  }

  get localizedList() {
    if (!this.args.targetChannels) {
      return [];
    }
    return this.args.targetChannels.map((channel) => {
      let metadata = CHANNEL_METADATA_MAPPING[channel];
      return this.intl.t('operator.workflows.channels.type', { channel: metadata.value });
    });
  }

  get selectedChannelsLabel() {
    if (this.localizedList.length === 0) {
      return this.intl.t('reporting.custom-bots.target-channel-selector.any-channel');
    }
    if (this.localizedList.length === 1) {
      return this.intl.t('reporting.custom-bots.target-channel-selector.single-channel', {
        channel: this.localizedList[0],
      });
    } else if (this.localizedList.length === 2) {
      return this.intl.t('reporting.custom-bots.target-channel-selector.double-channel', {
        channelOne: this.localizedList[0],
        channelTwo: this.localizedList[1],
      });
    } else {
      return this.intl.t('reporting.custom-bots.target-channel-selector.multiple-channels', {
        channelOne: this.localizedList[0],
        remainingChannelCount: this.localizedList.length - 1,
      });
    }
  }

  @action
  changeSelectedValues() {
    let { targetChannels } = this.args;

    if (targetChannels !== null) {
      this.selectedChannels = targetChannels;
    }
  }

  @action
  onClose() {
    let { additionalSearchableData, selectedChannels } = this;
    let data = { ...additionalSearchableData, targetChannels: selectedChannels };
    this.args.filterContent('additionalSearchableData', data);
  }

  @action
  onOpen() {
    this.selectedChannels = this.args.targetChannels || [];
  }

  @action
  selectChannel(selectedValues: (keyof typeof CHANNEL_METADATA_MAPPING)[]) {
    this.selectedChannels = selectedValues;
  }

  @action removeFilter() {
    let searchableData = { ...this.additionalSearchableData, targetChannels: '' };
    this.args.filterContent('additionalSearchableData', searchableData);
    this.args.removeFromActiveFilter && this.args.removeFromActiveFilter('target-channel');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::Filters::TargetChannelComponent': typeof TargetChannelComponent;
  }
}
