/* import __COLOCATED_TEMPLATE__ from './responses-configurator.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { responseProcessingBehaviours } from 'embercom/models/data/sms/constants';

export default class ResponsesConfigurator extends Component {
  @service store;

  @tracked smsOutboundSettings;

  responseProcessingBehaviours = responseProcessingBehaviours;

  get twoWaySmsState() {
    return this.smsOutboundSettings &&
      this.smsOutboundSettings.responseProcessingBehaviour ===
        responseProcessingBehaviours.deflectToInbox
      ? 'On'
      : 'Off';
  }

  @action
  confirmDone() {
    this.args.incrementStep();
    if (this.args.resetFilters) {
      this.args.resetFilters();
    }
  }

  @task
  *fetchSmsOutboundSettings() {
    let smsOutboundSettingsList = yield this.store.findAll('sms-outbound-settings');
    this.smsOutboundSettings =
      smsOutboundSettingsList.firstObject || this.store.createRecord('sms-outbound-settings');
  }
}
