/* import __COLOCATED_TEMPLATE__ from './save-or-cancel.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    ruleset: $TSFixMe;
    section: string;
    onSave: () => void;
    onCancel: () => void;
    savingDisabled?: boolean;
    disabledTooltipContent?: string;
  };
}

const SaveOrCancel = templateOnlyComponent<Signature>();
export default SaveOrCancel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::SaveOrCancel': typeof SaveOrCancel;
  }
}
