/* import __COLOCATED_TEMPLATE__ from './whats-next.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnly from '@ember/component/template-only';

const WhatsNext = templateOnly();

export default WhatsNext;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::WhatsNext': typeof WhatsNext;
  }
}
