/* import __COLOCATED_TEMPLATE__ from './empty-state-banner-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  containerClass: string;
  dismissalKey?: string;
  compact?: boolean;
  bodyContainerClass?: string;
  hidden?: boolean;
}

interface Signature {
  Element: any;
  Args: Args;
  Blocks: {
    default: [any];
  };
}

const EmptyStateBannerWrapper = templateOnlyComponent<Signature>();
export default EmptyStateBannerWrapper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Common::EmptyStateBannerWrapper': typeof EmptyStateBannerWrapper;
  }
}
