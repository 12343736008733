/* import __COLOCATED_TEMPLATE__ from './bulk-archive.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { put } from 'embercom/lib/ajax';
import { toPercentString } from 'embercom/lib/percentage-formatter';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import { contentWrapperTypes } from 'embercom/models/data/outbound/constants';
import { CAN_SEND_MESSAGES } from 'embercom/lib/outbound/constants';
import {
  CUSTOM_BOT_REQUIRED_PERMISSION,
  OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION,
} from 'embercom/lib/operator/custom-bots/constants';

export default class BulkArchive extends Component {
  @service appService;
  @service notificationsService;
  @service realTimeEventService;
  @service intl;
  @service router;
  @service permissionsService;

  @tracked showConfirmationModal = false;
  @tracked showArchiveResultsModal = false;
  @tracked failedContentWrappers = [];
  @tracked archivedContentWrappers = [];
  @tracked legacyMessageSelected = false;

  lastReceivedNexusTimestamp = 0;
  isWorkflowsOverviewPage = false;

  constructor() {
    super(...arguments);
    this.realTimeEventService.on('BulkArchiveStatus', this, 'showBulkArchiveUpdate');
    this.isWorkflowsOverviewPage =
      this.router?.currentRouteName === 'apps.app.automation.workflows-overview';
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off('BulkArchiveStatus', this, 'showBulkArchiveUpdate');
  }

  get app() {
    return this.appService.app;
  }

  // disable bulk action for series, legacy message types or messages that are live
  get isDisabled() {
    return this.args.selectedContentWrappers.any((contentWrapper) => {
      if (
        !contentWrapper?.relations?.series &&
        contentWrapper.data.contentWrapperType === contentWrapperTypes.ruleset &&
        contentWrapper?.contents?.firstObject?.state !== states.live
      ) {
        return false;
      }
      return true;
    });
  }

  get bulkArchiveUrlEndpoint() {
    return this.isWorkflowsOverviewPage
      ? '/ember/operator_workflows/bulk_archive'
      : '/ember/content_service/contents/bulk_archive';
  }

  @task
  *bulkArchiveItems() {
    try {
      yield this.canArchive();
      yield put(this.bulkArchiveUrlEndpoint, {
        app_id: this.app.id,
        content_wrapper_ids: this.args.selectedContentWrappers.map(
          (contentWrapper) => contentWrapper.id,
        ),
      });
    } catch (e) {
      let message = this.intl.t('outbound.bulk-archive.error-response');
      if (e && e.message) {
        message = e.message;
      }
      this.notificationsService.notifyError(message);
    }
    this.showConfirmationModal = false;
  }

  showBulkArchiveUpdate(event) {
    if (this.lastReceivedNexusTimestamp > event.timestamp) {
      return;
    }
    let notificationText = '';
    if (event.eventType === 'archiving') {
      notificationText = this.intl.t('outbound.bulk-archive.archive-progress-notification', {
        percentage: toPercentString(event.progress * 100),
      });
    } else if (event.eventType === 'complete') {
      this.archivedContentWrappers = event.success;
      this.failedContentWrappers = event.errors;
      this.deleteContentWrapper();
      if (this.failedContentWrappers.length) {
        this.showArchiveResultsModal = true;
        return;
      }
      if (this.archivedContentWrappers.length > 1) {
        notificationText = this.intl.t(
          'outbound.bulk-archive.archive-results-modal.multiple-items.success',
          {
            archivedContentWrappersLength: this.archivedContentWrappers.length,
          },
        );
      } else {
        notificationText = this.intl.t(
          'outbound.bulk-archive.archive-results-modal.single-item.success',
        );
      }
    }
    this.notificationsService.removeNotification('bulk-archive-messages');
    this.lastReceivedNexusTimestamp = event.timestamp;
    this.notificationsService.notifyConfirmation(notificationText, 5000, 'bulk-archive-messages');
  }

  get hasOnlyOutboundWorkflows() {
    return this.args.selectedContentWrappers.every((contentWrapper) => {
      return (
        contentWrapper.data.contentWrapperType === contentWrapperTypes.ruleset &&
        contentWrapper?.contents?.firstObject?.contentType === objectTypes.customBot
      );
    });
  }

  get hasOnlyNonOutboundWorkflows() {
    return this.args.selectedContentWrappers.every((contentWrapper) => {
      return (
        contentWrapper.data.contentWrapperType === contentWrapperTypes.ruleset &&
        contentWrapper?.contents?.firstObject?.contentType !== objectTypes.customBot
      );
    });
  }

  async canArchiveWorkflows() {
    if (this.hasOnlyOutboundWorkflows) {
      await this.permissionsService.checkPermission(OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION);
    } else if (this.hasOnlyNonOutboundWorkflows) {
      await this.permissionsService.checkPermission(CUSTOM_BOT_REQUIRED_PERMISSION);
    } else {
      await this.permissionsService.checkPermission(CUSTOM_BOT_REQUIRED_PERMISSION);
      await this.permissionsService.checkPermission(OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION);
    }
  }

  async canArchive() {
    if (this.isWorkflowsOverviewPage) {
      return this.canArchiveWorkflows();
    } else {
      return this.permissionsService.checkPermission(
        CAN_SEND_MESSAGES,
        this.intl.t('outbound.bulk-archive.permission-error'),
      );
    }
  }

  @action
  deleteContentWrapper() {
    let archivedContentWrapperIds = this.archivedContentWrappers.map(
      (contentWrapper) => contentWrapper.contentWrapperId,
    );
    this.args.deleteContentWrappers(archivedContentWrapperIds);
  }
}
