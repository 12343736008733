/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { DEFAULT_FIN_CHART_DEFINITION } from 'embercom/components/reporting/reports/fin/fin-report';
import { inject as service } from '@ember/service';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type IntlService from 'embercom/services/intl';
import type ReportingService from 'embercom/services/reporting-service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';

interface Signature {
  Element: HTMLDivElement;
}

const DEFAULT_FIN_METRICS_DEFINITION = {
  ...DEFAULT_FIN_CHART_DEFINITION,
  date_range: {
    property: 'first_user_conversation_part_created_at',
    selection: 'past_week',
    start: null,
    end: null,
  },
};

export default class Header extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare reportingService: ReportingService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare permissionsService: any;
  @service declare appService: any;
  @service declare store: Store;
  @tracked currentUsage = 0;
  @service declare usageLimitsService: any;
  @service declare customerService: any;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.fetchCurrentUsage).perform();
    taskFor(this.reportingMetrics.setup).perform();
  }

  get isUsageLimitBreached() {
    return this.usageLimitsService.finHardLimitBreached;
  }

  get finUsageLimitThreshold() {
    return this.usageLimitsService.finUsageLimitThreshold;
  }

  get finUsageLimitEnabled() {
    return this.usageLimitsService.finUsageLimitEnabled;
  }

  get contractedResolutionsUsageLimit() {
    return this.usageLimitsService.contractedResolutionsUsageLimit;
  }

  get isLoading() {
    return taskFor(this.reportingMetrics.setup).isRunning;
  }

  get canAccessToReporting() {
    return this.permissionsService.currentAdminCan('can_access_reporting');
  }

  get involvementChartDefinition() {
    return {
      ...DEFAULT_FIN_METRICS_DEFINITION,
      id: 'fin_involvement',
      title: this.intl.t('reporting.fin.involvement.title'),
      visualization_type: VISUALIZATION_TYPES.COUNTER,
      visualization_options: {
        show_time_comparison: true,
      },
      width_type: WIDTH_TYPES.FULL,
      height_type: HEIGHT_TYPES.X_TALL,
      chart_series: [
        {
          metric_id: 'fin.conversations_participated.count',
        },
      ],
    };
  }

  get resolvedConversationsDefinition() {
    return {
      ...DEFAULT_FIN_METRICS_DEFINITION,
      id: 'fin_resolved_conversations',
      title: this.intl.t('reporting.fin.involvement.title'),
      visualization_type: VISUALIZATION_TYPES.COUNTER,
      width_type: WIDTH_TYPES.FULL,
      height_type: HEIGHT_TYPES.X_TALL,
      chart_series: [
        {
          metric_id: 'fin.conversations_resolved.count',
        },
      ],
    };
  }

  get involvementRateDefinition() {
    return {
      ...DEFAULT_FIN_METRICS_DEFINITION,
      id: 'fin_involvement_percentage',
      title: this.intl.t('reporting.fin.involvement.title'),
      visualization_type: VISUALIZATION_TYPES.COUNTER,
      visualization_options: {
        show_time_comparison: true,
      },
      width_type: WIDTH_TYPES.FULL,
      height_type: HEIGHT_TYPES.X_TALL,
      chart_series: [
        {
          metric_id: 'fin.participation_rate',
        },
      ],
    };
  }

  get answerRateDefinition() {
    return {
      ...DEFAULT_FIN_METRICS_DEFINITION,
      id: 'fin_answer_percentage',
      title: this.intl.t('reporting.fin.involvement.title'),
      visualization_type: VISUALIZATION_TYPES.COUNTER,
      visualization_options: {
        show_time_comparison: true,
      },
      width_type: WIDTH_TYPES.FULL,
      height_type: HEIGHT_TYPES.X_TALL,
      chart_series: [
        {
          metric_id: 'fin.answer_rate',
        },
      ],
    };
  }

  get resolutionRateDefinition() {
    return {
      ...DEFAULT_FIN_METRICS_DEFINITION,
      id: 'fin_resolution_percentage',
      title: this.intl.t('reporting.fin.involvement.title'),
      visualization_type: VISUALIZATION_TYPES.COUNTER,
      visualization_options: {
        show_time_comparison: true,
      },
      width_type: WIDTH_TYPES.FULL,
      height_type: HEIGHT_TYPES.X_TALL,
      chart_series: [
        {
          metric_id: 'fin.resolution_rate',
        },
      ],
    };
  }

  get deflectionRateDefinition() {
    return {
      ...DEFAULT_FIN_METRICS_DEFINITION,
      id: 'deflection_rate',
      title: this.intl.t('reporting.fin.metrics.deflection-rate-percentage.name'),
      visualization_type: VISUALIZATION_TYPES.COUNTER,
      visualization_options: {
        show_time_comparison: true,
      },
      width_type: WIDTH_TYPES.FULL,
      height_type: HEIGHT_TYPES.X_TALL,
      chart_series: [
        {
          metric_id: 'fin.deflection_rate',
        },
      ],
    };
  }

  get app() {
    return this.appService.app;
  }

  get showBillingCard() {
    return this.app.onPricing5 || this.currentUsage;
  }

  get usageMetricParameters() {
    if (
      this.app.onPricing5 &&
      this.app.hasActiveSubscription &&
      !this.customerService.customer.inCardlessTrial
    ) {
      return {
        route: 'apps.app.billing.usage',
        buttonLabel: this.intl.t('operator.fin.setup.header.usage.button-usage-dashboard'),
      };
    }

    return {
      route: 'apps.app.billing.manage-usage',
      buttonLabel: this.intl.t('operator.fin.setup.header.usage.button'),
    };
  }

  @task({ restartable: true })
  *fetchCurrentUsage(): TaskGenerator<any> {
    let currentUsage = yield this.store.queryRecord('usage-reminders/current-usage', {});
    this.currentUsage = currentUsage.value ?? 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Header': typeof Header;
  }
}
