/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { ONBOARDING_STEP_KEYS } from 'embercom/models/data/sms/constants';

export const COUNTRY_CODE = {
  UNITED_STATES: 'US',
  CANADA: 'CA',
  UNITED_KINGDOM: 'GB',
  AUSTRALIA: 'AU',
};

export const countryData = {
  // TODO: Move all of the text to translations.
  [COUNTRY_CODE.UNITED_STATES]: {
    code: COUNTRY_CODE.UNITED_STATES,
    prefix: '+1',
    name: 'United States',
    flag: '🇺🇸',
    onboardingSteps: [
      ONBOARDING_STEP_KEYS.SELECT_NUMBER,
      ONBOARDING_STEP_KEYS.CONFIGURE_SMS,
      ONBOARDING_STEP_KEYS.PREVIEW_SMS,
    ],
    isTollFree: true,
    instructions: [
      {
        heading: 'Ensure you have consent',
        description:
          'To avoid fines and deliver a great experience for your customers, ensure you have the correct consent to message customers in line with TCPA laws.',
      },
      {
        heading: 'Comply with Time of Day restrictions',
        description:
          "The TCPA enforces the adherence to quiet hours (anytime before 8 am and after 9 pm) in the recipient's time zone for any marketing messages.",
      },
      {
        heading: 'Prohibited SMS Content',
        description:
          'There are particular subjects that are restricted and monitored over SMS in the US, including S.H.A.F.T which stands for Sex, Hate, Alcohol, Firearms, and Tobacco.',
        link: 'https://www.intercom.com/help/en/articles/6311178-staying-compliant-with-sms',
      },
    ],
  },
  [COUNTRY_CODE.CANADA]: {
    code: COUNTRY_CODE.CANADA,
    prefix: '+1',
    flag: '🇨🇦',
    name: 'Canada',
    onboardingSteps: [
      ONBOARDING_STEP_KEYS.SELECT_NUMBER,
      ONBOARDING_STEP_KEYS.CONFIGURE_SMS,
      ONBOARDING_STEP_KEYS.PREVIEW_SMS,
    ],
    instructions: [
      {
        heading: 'Ensure you have consent',
        description:
          'Ensure you have the correct opt-in consent to message customers, particularly for marketing and other non-essential communication.',
      },
      {
        heading: 'Comply with Time of Day restrictions',
        description:
          'Only send SMS messages during users daytime hours unless it is urgent - you can use our quiet hours feature to achieve this.',
      },
      {
        heading: 'Provide clear opt-out instructions',
        description:
          'Ensure that you provide clear and timely ways for users to opt-out of receiving SMS messages and campaigns.',
      },
    ],
  },
  [COUNTRY_CODE.UNITED_KINGDOM]: {
    code: COUNTRY_CODE.UNITED_KINGDOM,
    prefix: '+44',
    flag: '🇬🇧',
    name: 'United Kingdom',
    onboardingSteps: [
      ONBOARDING_STEP_KEYS.SELECT_NUMBER,
      ONBOARDING_STEP_KEYS.CONFIGURE_SMS,
      ONBOARDING_STEP_KEYS.PREVIEW_SMS,
    ],
    bundleRequired: true,
    instructions: [
      {
        heading: 'Ensure you have consent',
        description:
          'Ensure you have the correct opt-in consent to message customers, particularly for marketing and other non-essential communication.',
      },
      {
        heading: 'Comply with Time of Day restrictions',
        description:
          'Only send SMS messages during users daytime hours unless it is urgent - you can use our quiet hours feature to achieve this.',
      },
      {
        heading: 'Provide clear opt-out instructions',
        description:
          'Ensure that you provide clear and timely ways for users to opt-out of receiving SMS messages and campaigns.',
      },
    ],
  },
  [COUNTRY_CODE.AUSTRALIA]: {
    code: COUNTRY_CODE.AUSTRALIA,
    prefix: '+61',
    flag: '🇦🇺 ',
    name: 'Australia',
    onboardingSteps: [
      ONBOARDING_STEP_KEYS.SELECT_NUMBER,
      ONBOARDING_STEP_KEYS.CONFIGURE_SMS,
      ONBOARDING_STEP_KEYS.PREVIEW_SMS,
    ],
    bundleRequired: true,
    instructions: [
      {
        heading: 'Ensure you have consent',
        description:
          'Ensure you have the correct opt-in consent to message customers, particularly for marketing and other non-essential communication.',
      },
      {
        heading: 'Comply with Time of Day restrictions',
        description:
          'Only send SMS messages during users daytime hours unless it is urgent - you can use our quiet hours feature to achieve this.',
      },
      {
        heading: 'Provide clear opt-out instructions',
        description:
          'Ensure that you provide clear and timely ways for users to opt-out of receiving SMS messages and campaigns.',
      },
    ],
  },
};

// list of countries that require TOLL FREE VERIFICATION.
export const TOLL_FREE_VERIFICATION_COUNTRY_LIST = [COUNTRY_CODE.UNITED_STATES];

export const FILTERED_COUNTRIES_FROM_ONBOARDING = [
  COUNTRY_CODE.AUSTRALIA,
  COUNTRY_CODE.UNITED_KINGDOM,
];

export const COUNTRIES_REQUIRING_REGULATORY_BUNDLES = [COUNTRY_CODE.UNITED_KINGDOM];
