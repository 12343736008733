/* import __COLOCATED_TEMPLATE__ from './get-started.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { FIN_PRICE_PER_RESOLUTION } from 'embercom/lib/billing';
interface GetStartedArgs {
  requireFinIngestionConsent: boolean;
  dismissLandingPage: () => void;
}

export default class GetStarted extends Component<GetStartedArgs> {
  @service declare appService: any;
  @service declare intercomEventService: any;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    if (this.args.requireFinIngestionConsent) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'ingestion_consent_page',
      });
    }
  }

  get app(): any {
    return this.appService.app;
  }

  get finPricePerResolution(): number {
    return FIN_PRICE_PER_RESOLUTION;
  }

  get canCreateFinFreeUsageWindow(): boolean {
    return this.app.canCreateFinFreeUsageWindow;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::GetStarted': typeof GetStarted;
  }
}
