/* import __COLOCATED_TEMPLATE__ from './channels-button-group.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

type Channel = 'chat' | 'email';

export interface ChannelsButtonGroupArgs {
  showIcons?: boolean;
  onChannelChange: (value: Channel) => void;
  chatLive?: boolean;
  emailLive?: boolean;
}

export default class ChannelsButtonGroup extends Component<ChannelsButtonGroupArgs> {
  @tracked declare selectedChannel: Channel;

  constructor(owner: unknown, args: ChannelsButtonGroupArgs) {
    super(owner, args);
    this.selectedChannel = 'chat';
  }

  get chatSelected() {
    return this.selectedChannel === 'chat';
  }

  get emailSelected() {
    return this.selectedChannel === 'email';
  }

  @action
  onChannelChange(value: Channel): void {
    this.selectedChannel = value;
    if (this.args.onChannelChange) {
      this.args.onChannelChange(value);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ChannelsButtonGroup': typeof ChannelsButtonGroup;
  }
}
