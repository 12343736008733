/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ApplicationInstance from '@ember/application/instance';
import { tracked } from '@glimmer/tracking';
import { postRequest, putRequest } from 'embercom/lib/inbox/requests';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type { Brand, BrandWireFormat } from './types/brand';
import type { HelpCenter } from './types/help-center';
import type { SenderEmailAddressSettings } from './types/sender-email-address-settings';
import { ref } from 'ember-ref-bucket';
import { isValidEmail } from 'embercom/lib/email';

interface Args {
  type: 'new' | 'edit';
  isOpen: boolean;
  onClose: (brand: Brand | undefined) => void;
  helpCenters: HelpCenter[];
  onNewHelpCenter: (helpCenter: HelpCenter) => void;
  senderEmailAddressSettings?: SenderEmailAddressSettings[]; // only applicable for edit-brand sidebar
  onNewSenderEmailAddressSettings: (senderEmailAddressSettings: SenderEmailAddressSettings) => void;
  brand?: Brand; // only applicable for edit-brand sidebar
}

interface Signature {
  Args: Args;
  Element: never;
}

interface BrandRequestParams {
  app_id: string;
  filename?: string;
  file_data?: string;
  help_center_id?: number;
  sender_email_address_settings_id?: number;
  notification_email_address?: string;
  name?: string;
}

export default class Sidebar extends Component<Signature> {
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;

  @tracked name?: string;
  @tracked filename?: string;
  @tracked fileData?: string;
  @tracked helpCenterId?: number;
  @tracked senderEmailAddressSettingsId?: number; // only applicable for edit-brand sidebar
  @tracked notificationEmailAddress?: string; // only applicable for new-brand sidebar
  @tracked logoUrl?: string; // this is only for display purposes, it is not sent as part of any payload

  @tracked isNewAddressModalOpen = false;
  @tracked isNewHelpCenterModalOpen = false;
  @tracked isEmailAddressInvalid = false;

  @ref('logoInput') declare logoInput: HTMLInputElement;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    // The `&& this.args.brand` here is just to make typescript happy. If this
    // is an edit sidebar, then `this.args.brand` is always passed.
    if (this.isEditSidebar && this.args.brand) {
      this.name = this.args.brand.name;
      this.helpCenterId = this.args.brand.helpCenterId || undefined;
      this.senderEmailAddressSettingsId = this.args.brand.senderEmailAddressSettingsId || undefined;
      this.logoUrl = this.args.brand.logoUrl;
    }
  }

  get isNewSidebar() {
    return this.args.type === 'new';
  }

  get isEditSidebar() {
    return this.args.type === 'edit';
  }

  @action
  handleClose(brand: Brand | undefined) {
    this.args.onClose(brand);
  }

  get helpCenterItems() {
    return this.args.helpCenters.map((helpCenter) => ({
      value: helpCenter.id,
      text: helpCenter.name,
    }));
  }

  @action
  onSelectHelpCenter(helpCenterId: number) {
    this.helpCenterId = helpCenterId;
  }

  @action
  handleClickNewHelpCenter() {
    this.isNewHelpCenterModalOpen = true;
  }

  @action
  handleCloseNewHelpCenterModal() {
    this.isNewHelpCenterModalOpen = false;
  }

  @action
  handleCreateNewHelpCenter(helpCenter: HelpCenter) {
    this.args.onNewHelpCenter(helpCenter);
    this.helpCenterId = helpCenter.id;
  }

  get senderEmailAddressSettingsItems() {
    return this.args.senderEmailAddressSettings && this.args.senderEmailAddressSettings?.length
      ? this.args.senderEmailAddressSettings
          .filter((senderEmailAddressSettings) =>
            this.args.brand!.isDefault
              ? !senderEmailAddressSettings.brandId
              : senderEmailAddressSettings.brandId?.toString() === this.args.brand!.id.toString(),
          )
          .map((senderEmailAddressSetting) => ({
            value: senderEmailAddressSetting.id,
            text: senderEmailAddressSetting.email,
            isDisabled: !senderEmailAddressSetting.verified,
          }))
      : [];
  }

  @action
  onSelectSenderEmailAddressSetting(senderEmailAddressSettingsId: number) {
    this.senderEmailAddressSettingsId = senderEmailAddressSettingsId;
  }

  @action
  handleClickNewAddress() {
    this.isNewAddressModalOpen = true;
  }

  @action
  handleCloseNewAddressModal() {
    this.isNewAddressModalOpen = false;
  }

  @action
  handleCreateNewAddress(senderEmailAddressSettings: SenderEmailAddressSettings) {
    this.args.onNewSenderEmailAddressSettings(senderEmailAddressSettings);
    this.senderEmailAddressSettingsId = senderEmailAddressSettings.id;
  }

  @action
  handleClickMessengerStyles() {
    safeWindowOpen(
      '/a/apps/_/settings/channels/messenger/web?section=general&tab=styling',
      '_blank',
    );
  }

  @action
  validateEmail(event: KeyboardEvent & { target: HTMLInputElement }) {
    this.notificationEmailAddress = event.target.value;
    if (this.notificationEmailAddress && !isValidEmail(this.notificationEmailAddress)) {
      this.isEmailAddressInvalid = true;
    } else {
      this.isEmailAddressInvalid = false;
    }
  }

  get isReady() {
    let emailCheck = true;
    if (this.isNewSidebar && this.notificationEmailAddress) {
      emailCheck = isValidEmail(this.notificationEmailAddress);
    }
    return this.name && this.helpCenterId !== undefined && emailCheck;
  }

  @dropTask
  *saveTask(): TaskGenerator<void> {
    let wireFormat: BrandWireFormat;
    try {
      let params: BrandRequestParams = {
        app_id: this.appService.app.id,
        filename: this.filename,
        file_data: this.fileData,
        help_center_id: this.helpCenterId,
        sender_email_address_settings_id: this.senderEmailAddressSettingsId,
        notification_email_address: this.notificationEmailAddress,
      };

      if (!this.args.brand?.isDefault) {
        params['name'] = this.name;
      }
      let response;
      let object;
      if (this.isNewSidebar) {
        response = yield postRequest(`/ember/brands`, params);
        object = 'create_brand_button';
      } else {
        response = yield putRequest(`/ember/brands/${this.args.brand?.id}`, params);
        object = 'update_brand_button';
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object,
        app_id: this.appService.app.id,
        filename: this.filename,
        file_data: this.fileData,
        help_center_id: this.helpCenterId,
        sender_email_address_settings_id: this.senderEmailAddressSettingsId,
        notification_email_address: this.notificationEmailAddress,
        brand_id: object === 'update_brand_button' ? this.args.brand?.id : undefined,
      });
      wireFormat = yield response.json() as BrandWireFormat;
    } catch (error) {
      console.error('saveTask() error', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-save-brand'),
      });
      return;
    }
    let brand = {
      id: wireFormat.id,
      name: wireFormat.name,
      logoId: wireFormat.logo_id,
      logoUrl: wireFormat.logo_url,
      helpCenterId: wireFormat.help_center_id,
      senderEmailAddressSettingsId: wireFormat.sender_email_address_settings_id,
      isDefault: wireFormat.is_default,
    } as Brand;
    // When a new brand is created, and the user has input a notificationEmailAddress,
    // a new SenderEmailAddressSettings is created on the back-end. The parent Brands
    // component needs to know about this, so we trigger this callback to inform it
    if (
      this.isNewSidebar &&
      brand.senderEmailAddressSettingsId &&
      this.notificationEmailAddress &&
      this.args.onNewSenderEmailAddressSettings
    ) {
      this.args.onNewSenderEmailAddressSettings({
        id: brand.senderEmailAddressSettingsId,
        email: this.notificationEmailAddress,
        brandId: brand.id,
      });
    }
    this.handleClose(brand);
  }

  @action
  handleSave() {
    try {
      taskFor(this.saveTask).perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.workspace.brands.brand-saved-successfully'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-save-brand'),
      });
    }
  }

  get isSaving() {
    return taskFor(this.saveTask).isRunning;
  }

  get saveDisabled(): boolean {
    let canSave = this.isReady && !this.isSaving;
    return !canSave;
  }

  @dropTask
  *processLogo() {
    yield new Promise((resolve) => {
      let reader = new FileReader();
      let file = this.logoInput.files![0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.logoUrl = reader.result as string;
        this.filename = file.name;
        this.fileData = this.logoUrl.split(',')[1];
        resolve(undefined);
      };
    });
  }

  @action
  handleLogoInput() {
    if (this.logoInput.files && this.logoInput.files.length === 0) {
      return;
    }

    taskFor(this.processLogo).perform();
  }

  @action
  openFilePicker() {
    this.logoInput.click();
  }

  get brand(): Brand {
    return this.args.brand!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::Sidebar': typeof Sidebar;
  }
}
