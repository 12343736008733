/* import __COLOCATED_TEMPLATE__ from './get-started-header.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface GetStartedHeaderArgs {
  dismissLandingPage: any;
  showIngestionTerms: boolean;
}

export default class GetStartedHeader extends Component<GetStartedHeaderArgs> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get ctaLabel(): string {
    if (this.canCreateFinFreeUsageWindow) {
      return this.intl.t('operator.fin.get-started.try-fin-free-button');
    } else {
      return this.intl.t('operator.fin.get-started.try-fin-button');
    }
  }

  get canCreateFinFreeUsageWindow(): boolean {
    return this.appService.app.canCreateFinFreeUsageWindow;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::GetStartedHeader': typeof GetStartedHeader;
    'operator/fin/get-started-header': typeof GetStartedHeader;
  }
}
