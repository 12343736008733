/* import __COLOCATED_TEMPLATE__ from './who-will-see-fin.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { action } from '@ember/object';
// @ts-ignore
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import { CHANNEL_EMAIL, CHANNEL_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type Profile from 'embercom/models/ai-agent/profile';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
  channelType: ChannelType;
}

export default class WhoWillSeeFin extends Component<Args> {
  @service declare attributeService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  ignoredAttributeTypesForPreview = ['message', 'conversation', 'office_hours', 'team_capacity'];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get userTypes() {
    return this.ruleset?.rolePredicateGroup?.userPredicate?.userTypes || [];
  }

  get rulesetHasPredicates() {
    if (!this.ruleset.predicateGroup.predicates) {
      return false;
    }

    return !!this.ruleset.predicateGroup.predicates.length;
  }

  get rulesetPredicatesCount() {
    if (!this.ruleset.predicateGroup.predicates) {
      return 0;
    }

    return (
      this.ruleset.predicateGroup.predicates?.firstObject?.predicates?.length ||
      this.ruleset.predicateGroup.predicates.length
    );
  }

  get additionalAttributes() {
    return this.attributeService.visualBotBuilderConditionalAttributes;
  }

  get excludedChannels() {
    return [CHANNEL_EMAIL, CHANNEL_PHONE_CALL];
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get targetChannels(): string {
    let targetChannels = this.behavior.targetChannels.map((channel: string) => {
      return this.intl.t('operator.workflows.channels.type', { channel });
    });

    if (targetChannels.length > 3) {
      return this.intl.t('operator.workflows.channels.multiple-channels', {
        channelOne: targetChannels[0],
        remainingChannelCount: targetChannels.length - 1,
      });
    }
    return this.intl.formatList(targetChannels, {
      type: 'conjunction',
    });
  }

  @action
  onUpdatePredicates(predicates: any) {
    this.ruleset.set('predicateGroup.predicates', copy(predicates));

    this.ruleset.get('fetchAudiencePreviewTask').perform(this.ignoredAttributeTypesForPreview);
  }

  @action
  onUpdateRolePredicates(rolePredicates: any) {
    this.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));

    this.ruleset.get('fetchAudiencePreviewTask').perform(this.ignoredAttributeTypesForPreview);
  }

  @action
  async updateAudienceRuleAndTargetChannels() {
    let target_channels = this.behavior.targetChannels;

    let audience_rules = {
      predicate_group: this.ruleset.predicateGroup,
      client_predicate_group: this.ruleset.clientPredicateGroup,
      role_predicate_group: this.ruleset.rolePredicateGroup,
      target_channels,
    };

    this.args.partialSave({ audience_rules });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::WhoWillSeeFin': typeof WhoWillSeeFin;
  }
}
