/* import __COLOCATED_TEMPLATE__ from './create-template-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CreateTemplateButtons extends Component {
  @service appService;
  @service paywallService;
  @service router;
  @service intercomEventService;

  get app() {
    return this.appService.app;
  }

  async _transitionAfterPaywallCheck(destination) {
    try {
      await this.paywallService.paywall({ featureName: 'custom_email_templates' });
      this.router.transitionTo(destination);
    } catch {
      this.paywallService.handleError;
    }
  }

  @action
  createNewCustomEmailTemplate() {
    this.trackAnalyticsEvent('create_html_template');
    this._transitionAfterPaywallCheck('apps.app.outbound.settings.email-templates.new-template');
  }

  @action
  createNewVisualCustomEmailTemplate() {
    this.trackAnalyticsEvent('create_visual_template');
    this._transitionAfterPaywallCheck(
      'apps.app.outbound.settings.email-templates.new-visual-template',
    );
  }

  trackAnalyticsEvent(object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
    });
  }
}
