/* import __COLOCATED_TEMPLATE__ from './custom-template-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { sortAndFilterCustomTemplates } from 'embercom/lib/outbound/email-template-helper';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CustomTemplateList extends Component {
  @service appService;
  @tracked emailTemplateInputFilter = '';

  get app() {
    return this.appService.app;
  }

  get allCustomEmailTemplates() {
    return sortAndFilterCustomTemplates(this.args.emailTemplateTitles);
  }

  get customEmailTemplates() {
    return this.filterTemplates();
  }

  get editTemplateRoute() {
    return this.app.canSeeIASettingsGA
      ? 'apps.app.settings.channels.email.edit-template'
      : 'apps.app.outbound.settings.email-templates.edit-template';
  }

  @action
  filterTemplates() {
    if (this.emailTemplateInputFilter !== '') {
      return this.allCustomEmailTemplates.filter((template) =>
        template.name.toUpperCase().includes(this.emailTemplateInputFilter.toUpperCase()),
      );
    }
    return this.allCustomEmailTemplates;
  }
}
