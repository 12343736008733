/* import __COLOCATED_TEMPLATE__ from './email-verification.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { cached } from 'tracked-toolbox';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';
import resendSenderEmailVerificationLink from 'embercom/lib/settings/resend-sender-email-verification-link';
import type IntlService from 'ember-intl/services/intl';
import { type DomainEmailAddress } from './tab';

interface Args {
  customSenderAddresses: DomainEmailAddress[];
  domainName: string;
}

export default class EmailVerification extends Component<Args> {
  @service appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked showAddCustomSenderModal = false;

  get app() {
    return this.appService.app;
  }

  get hasPermissionForCustomAddresses() {
    return this.app.currentAdmin.currentAppPermissions.can_send_from_custom_addresses;
  }

  @cached
  get entities() {
    return this.args.customSenderAddresses.map((address) => ({
      text: address.email,
      value: address.id || address.senderEmailAddresses[0].id,
    }));
  }

  @use
  selectedEmailIdLoader = AsyncData<string>(() => {
    return this.entities.length ? this.entities[0].value : '';
  });

  @action
  onEmailChange(emailId: string) {
    this.selectedEmailIdLoader.update(emailId);
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_domains_page',
      });
    }
  }

  @action
  resendVerificationLink() {
    resendSenderEmailVerificationLink(this.selectedEmailIdLoader.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::EmailVerification': typeof EmailVerification;
  }
}
