/* import __COLOCATED_TEMPLATE__ from './ask-for-rating.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { action } from '@ember/object';
// @ts-ignore
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type IntlService from 'embercom/services/intl';
import type Profile from 'embercom/models/ai-agent/profile';
import { FIN_CSAT_COMPATIBLE_CHANNELS } from 'embercom/lib/operator/resolution-bot/constants';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
}

export default class AskForRating extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked showCsatBlockAfterUpdate =
    this.behavior.csatBlockUpdateAfter !== undefined && this.behavior.csatBlockUpdateAfter !== null;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get compatibleWithFinCSAT() {
    let targetChannels = this.behavior.targetChannels || [];
    if (!targetChannels.length) {
      return true;
    }

    let incompatibleChannels = targetChannels.filter((channel: string) => {
      return !FIN_CSAT_COMPATIBLE_CHANNELS.includes(channel);
    });

    return !incompatibleChannels.length;
  }

  @action
  async updateRatingConfig() {
    let csat_settings = {
      enabled: this.behavior.csatEnabled,
      block_update_after: this.behavior.csatBlockUpdateAfter,
    };

    this.args.partialSave({ csat_settings });
  }

  @action
  toggleCsatEnabled() {
    this.behavior.csatEnabled = !this.behavior.csatEnabled;

    if (!this.behavior.csatEnabled) {
      this.behavior.csatBlockUpdateAfter = undefined;
      this.showCsatBlockAfterUpdate = false;
    }
  }

  @action
  toggleRatingChange() {
    this.showCsatBlockAfterUpdate = !this.showCsatBlockAfterUpdate;

    if (!this.showCsatBlockAfterUpdate) {
      this.behavior.csatBlockUpdateAfter = undefined;
    }
  }

  @action
  setBlockUpdateAfter(e: InputEvent & { target: HTMLInputElement }) {
    let value = parseInt((e.target as HTMLInputElement).value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    this.behavior.csatBlockUpdateAfter = value * 3600;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::AskForRating': typeof AskForRating;
  }
}
