/* import __COLOCATED_TEMPLATE__ from './demo-preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';

interface DemoPreviewSignature {
  Element: any;
  Args: {
    appId?: number | undefined;
    shareableFinToken?: any;
    languageOverride?: string;
    customActionId?: any;
  };
}

export default class DemoPreview extends Component<DemoPreviewSignature> {
  @service declare appService: any;
  @service declare session: any;

  get appId() {
    return this.args.appId || this.appService.app.id;
  }

  bindIFrame = modifier((el) => {
    let iframe = document.createElement('iframe');
    // Add `&local_client=true` to the end of the URL to see changes to intercom-js code in the preview.
    let iframeUrl = `${window.location.origin}/hosted_messenger/${this.appId}/fin_demo_preview?preview_only=true`;
    iframeUrl += `&language_override=${this.args.languageOverride ?? 'en'}`;
    if (this.args.shareableFinToken) {
      iframeUrl += `&source=shareablefinpreview&token=${this.args.shareableFinToken}`;
    }
    if (this.args.customActionId) {
      iframeUrl += `&custom_action_id=${this.args.customActionId}`;
    }

    iframe.src = iframeUrl;
    iframe.setAttribute('id', 'hosted-messenger-fin-demo-preview');

    el.replaceChildren(iframe);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DemoPreview: typeof DemoPreview;
    'Operator::Fin::DemoPreview': typeof DemoPreview;
  }
}
