/* import __COLOCATED_TEMPLATE__ from './rendered-template-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import EmailTemplate from 'embercom/models/email-template';
import Translations from 'embercom/models/translations';
import renderEmailTemplate from 'embercom/lib/messages/render-email-template';

const templateTrustedList = ['personal', 'company', 'announcement'];

export default class RenderedTemplateList extends Component {
  @service appService;
  @service store;
  @service router;

  get app() {
    return this.appService.app;
  }

  get renderedTemplates() {
    return this.standardTemplates.map((template) => {
      return {
        html: this._renderedTemplate(template),
        template,
      };
    });
  }

  get standardTemplates() {
    EmailTemplate.populateDefaultTemplates(this.app, Translations.create());
    return this.store
      .peekAll('email-template')
      .filter((template) => templateTrustedList.includes(template.id));
  }

  get renderedWidgets() {
    let rendered = {};
    let templateContext = this.widgetTemplateContext;
    EmailTemplate.widgets().forEach(function (widget) {
      rendered[widget.name] = widget.template(templateContext);
    }, this);
    return rendered;
  }

  get emailTemplateData() {
    return this.store.peekAll('email-template-data');
  }

  get widgetTemplateContext() {
    let context = {};
    this.emailTemplateData.forEach(function (emailTemplateDatum) {
      context[emailTemplateDatum.identifier] = emailTemplateDatum.value;
    }, this);

    if (!context.logo) {
      context.logo = { url: this.placeholderLogoUrl };
    }
    return context;
  }

  get placeholderLogoUrl() {
    return 'https://static.intercomassets.com/assets/wizard/sample_logo-0f637b81241b1b7930155af85db4314f.png';
  }

  _renderedTemplate(model) {
    return renderEmailTemplate({
      id: model.id,
      template: model.template,
      color: this.app.application_colors.base,
      renderedWidgets: this.renderedWidgets,
    });
  }

  @action
  copyTemplate(templateName) {
    this.router.transitionTo('apps.app.outbound.settings.email-templates.new-template', {
      queryParams: { templateId: templateName },
    });
  }
}
