/* import __COLOCATED_TEMPLATE__ from './auto-close-if-customer-becomes-inactive.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import type Profile from 'embercom/models/ai-agent/profile';
import { type Block } from 'embercom/models/common/blocks/block';
import type BotIntroPart from 'embercom/models/operator/bot-intro/part';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  isReadOnly?: boolean;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
}

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export default class AutoCloseIfCustomerBecomesInactive extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked days: number | string | undefined;
  @tracked hours: number | string | undefined;
  @tracked minutes: number | string | undefined;
  @tracked autoCloseWhenAnswer: boolean | undefined;
  @tracked autoCloseWhenNoAnswer: boolean | undefined;

  MIN_INACTIVITY_DURATION = 60;
  MAX_INACTIVITY_DURATION = 180 * 24 * 60 * 60; // 180 days in seconds

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.setAutoCloseSettings();
    this.setInactivityTimer();
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get profile(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get autoCloseMessages() {
    return this.profile.userInactivityAutoCloseMessages.toArray();
  }

  get durationInDaysHoursMinutes() {
    let formatKey = this.determineFormatKey();
    return this.intl.t(
      `operator.fin.setup.setup-and-go-live.fin-auto-close.duration-summary.${formatKey}`,
      {
        days: this.parseInt(this.days),
        hours: this.parseInt(this.hours),
        minutes: this.parseInt(this.minutes),
      },
    );
  }

  get userInactivityModeWhenAnswer() {
    if (this.profile.botMode === 'bot_only') {
      return 'closed';
    }
    return this.autoCloseWhenAnswer ? 'closed' : 'routed';
  }

  get userInactivityModeWhenNoAnswer() {
    if (this.profile.botMode === 'bot_only') {
      return 'closed';
    }
    return this.autoCloseWhenNoAnswer ? 'closed' : 'routed';
  }

  get hasInvalidAutoCloseMessages() {
    return this.profile.userInactivityAutoCloseMessages.any((part: $TSFixMe) => {
      return part.validations?.isInvalid;
    });
  }

  get invalidAutoCloseMessages() {
    return this.intl.t('operator.workflows.visual-builder.validations.fin.blank-message-error');
  }

  get timerInvalid() {
    return (
      this.profile.endUserInactivityTimer < this.MIN_INACTIVITY_DURATION ||
      this.profile.endUserInactivityTimer > this.MAX_INACTIVITY_DURATION
    );
  }

  get timerInvalidMessage() {
    return this.intl.t('operator.workflows.visual-builder.validations.fin.timer');
  }

  get settingsInvalid() {
    return this.timerInvalid || this.hasInvalidAutoCloseMessages;
  }

  get settingsInvalidMessage() {
    if (this.timerInvalid) {
      return this.timerInvalidMessage;
    }
    return this.invalidAutoCloseMessages;
  }

  @action
  updateInactivityDuration(number: number) {
    this.profile.endUserInactivityTimer = number;
    this.setInactivityTimer();
  }

  @action
  async updateAutoClose() {
    let auto_close_settings = {
      user_inactivity_mode_when_answer: this.userInactivityModeWhenAnswer,
      user_inactivity_mode_when_no_answer: this.userInactivityModeWhenNoAnswer,
      end_user_inactivity_timer: this.profile.endUserInactivityTimer,
      user_inactivity_auto_close_messages: this.profile.userInactivityAutoCloseMessages.serialize(),
    };

    this.args.partialSave({ auto_close_settings });
  }

  parseInt(value: string | number | undefined): number {
    if (value === undefined) {
      return 0;
    } else if (value === '') {
      return 0;
    } else if (typeof value === 'string') {
      return parseInt(value, 10);
    } else {
      return value;
    }
  }

  setAutoCloseSettings() {
    this.autoCloseWhenAnswer = this.profile.userInactivityModeWhenAnswer === 'closed';
    this.autoCloseWhenNoAnswer = this.profile.userInactivityModeWhenNoAnswer === 'closed';
  }

  setInactivityTimer() {
    let inactivityTimer = this.profile.endUserInactivityTimer;

    this.days = Math.floor((inactivityTimer || 0) / DAY);
    this.hours = Math.floor(((inactivityTimer || 0) % DAY) / HOUR);
    this.minutes = Math.floor((((inactivityTimer || 0) % DAY) % HOUR) / MINUTE);
  }

  determineFormatKey() {
    let formatKey = '';

    if (this.parseInt(this.days) > 0) {
      formatKey += 'd';
    }
    if (this.parseInt(this.hours) > 0) {
      formatKey += 'h';
    }
    if (this.parseInt(this.minutes) > 0) {
      formatKey += 'm';
    }

    if (formatKey === '') {
      formatKey = 'empty';
    }

    return formatKey;
  }

  @action
  reorderAutoCloseMessages(parts: BotIntroPart[]) {
    this.profile.userInactivityAutoCloseMessages.clear();
    this.profile.userInactivityAutoCloseMessages.pushObjects(parts);
  }

  @action
  removeAutoCloseMessage(part: Block) {
    this.profile.userInactivityAutoCloseMessages.removeObject(part);
  }

  @action
  addAutoCloseMessage() {
    this.profile.userInactivityAutoCloseMessages.createFragment();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::AutoCloseIfCustomerBecomesInactive': typeof AutoCloseIfCustomerBecomesInactive;
  }
}
