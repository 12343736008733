/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
}

export default class EmailPreview extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  @tracked declare finPreviewAddress: string;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    taskFor(this.fetchFinPreviewAddress).perform();
  }

  @task({ drop: true })
  *fetchFinPreviewAddress(): TaskGenerator<void> {
    let finPreviewSettings = yield this.store.findRecord(
      'ai-agent/preview-settings',
      this.appService.app.id,
    );
    this.finPreviewAddress = finPreviewSettings.previewAddress;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::EmailPreview': typeof EmailPreview;
    'operator/fin/setup/email-preview': typeof EmailPreview;
  }
}
