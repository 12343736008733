/* import __COLOCATED_TEMPLATE__ from './advanced.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  model: $TSFixMe;
}

const Advanced = templateOnlyComponent<Args>();
export default Advanced;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced': typeof Advanced;
  }
}
