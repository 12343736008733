/* import __COLOCATED_TEMPLATE__ from './self-assign-section.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  selfAssignedSelection: string;
}

interface Signature {
  Args: Args;
  Element: never;
}

const SelfAssignSection = templateOnlyComponent<Signature>();
export default SelfAssignSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::TabsComponents::SelfAssignSection': typeof SelfAssignSection;
  }
}
