/* import __COLOCATED_TEMPLATE__ from './invite-card-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class InviteCardComponent extends Component {
  @service appService;
  @service intercomEventService;
  @service router;

  get app() {
    return this.appService.app;
  }

  get buttonType() {
    return this.args.additionalClass ? 'primary' : 'secondary';
  }

  @action
  openTeammateSettings() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.baseAnalyticsMetadata,
      action: 'clicked',
      object: 'invite_teammate',
    });

    this.router.transitionTo('apps.app.settings.teammates');
  }
}
