/* import __COLOCATED_TEMPLATE__ from './branch-predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class BranchPredicatesEditor extends Component {
  @service appService;
  @service attributeService;
  @tracked predicateGroup = this.args.control.predicateGroup;

  get attributeGroupList() {
    return this.args.editorConfiguration
      ? this.args.editorConfiguration.runtimeMatchingTargetingAttributes
      : this.attributeService.visualBotBuilderConditionalAttributesGroupList;
  }

  get supportedPredicateAttributes() {
    if (
      !this.appService.app?.canUseWorkflowsTicketsV3 &&
      !this.appService.app?.canUseJiraInboundWorkflows
    ) {
      return undefined;
    }

    if (!this.args.editorConfiguration) {
      return undefined;
    }

    return this.args.editorConfiguration.runtimeMatchingTargetingAttributeIdentifiers;
  }

  @action
  onPredicatesUpdate(predicates) {
    this.args.control.set('predicateGroup.predicates', copy(predicates));
  }
}
