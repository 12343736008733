/* import __COLOCATED_TEMPLATE__ from './away-section.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { ref } from 'ember-ref-bucket';
import { action } from '@ember/object';

interface Args {
  initialInactivityEnabled: boolean;
  initialInactivityThreshold: number;
  followingInactivityEnabled: boolean;
  followingInactivityThreshold: number;
  autoAwaySetting: any;
  updateTrackedProperty: (key: string, value: any) => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class AwaySection extends Component<Signature> {
  @service declare appService: any;

  @ref('following-inactivity-threshold-input') followingInactivityThresholdInput: any;
  @ref('initial-inactivity-threshold-input') initialInactivityThresholdInput: any;

  get app() {
    return this.appService.app;
  }

  @action
  toggleInitialInactivity() {
    this.args.updateTrackedProperty(
      'initialInactivityEnabled',
      !this.args.initialInactivityEnabled,
    );
    if (this.args.initialInactivityEnabled) {
      this.initialInactivityThresholdInput.disabled = false;
      this.initialInactivityThresholdInput.focus();
    }
  }

  @action
  toggleFollowingInactivity() {
    this.args.updateTrackedProperty(
      'followingInactivityEnabled',
      !this.args.followingInactivityEnabled,
    );
    if (this.args.followingInactivityEnabled) {
      this.followingInactivityThresholdInput.disabled = false;
      this.followingInactivityThresholdInput.focus();
    }
  }

  @action
  setInitialInactivityThreshold(event: any) {
    this.args.updateTrackedProperty('initialInactivityThreshold', event.target?.value);
  }

  @action
  setFollowingInactivityThreshold(event: any) {
    this.args.updateTrackedProperty('followingInactivityThreshold', event.target?.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::TabsComponents::AwaySection': typeof AwaySection;
  }
}
