/* import __COLOCATED_TEMPLATE__ from './modal-form.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import IntercomTimezoneOptions from 'embercom/lib/intercom-timezone-options';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ModalForm extends Component {
  @service intl;
  @service appService;
  @service intercomEventService;

  @tracked automaticResponseDelay = 3;
  @tracked customResponseDelay = 4;
  @tracked showDependentMessages = false;
  @tracked dependentRulesets = [];
  @tracked responseDelayOptionsWithAutomatic = [
    {
      text: this.intl.t('messenger.office-hours.few-minutes'),
      id: 'expected-response-delay-minutes',
      value: 0,
    },
    {
      text: this.intl.t('messenger.office-hours.few-hours'),
      id: 'expected-response-delay-hours',
      value: 1,
    },
    {
      text: this.intl.t('messenger.office-hours.in-a-day'),
      id: 'expected-response-delay-day',
      value: 2,
    },
    {
      text: this.intl.t('messenger.office-hours.custom-time-option'),
      id: 'expected-response-delay-custom',
      value: 4,
    },
    {
      text: this.automaticResponseDelayText,
      description: this.intl.t('messenger.office-hours.dynamic-reply-type-description'),
      id: 'expected-response-delay-automatic',
      value: 3,
    },
  ];

  constructor() {
    super(...arguments);
    this.intl.maybeLoadMessengerTranslations();
  }

  get app() {
    return this.appService.app;
  }

  get modalTitle() {
    if (this.args.isEditingSchedule) {
      return this.intl.t('components.settings.office-hours.modal.title.edit-office-hours', {
        isDefault: this.schedule.isDefault,
      });
    } else {
      return this.intl.t('components.settings.office-hours.modal.title.new-office-hours');
    }
  }

  get automaticResponseDelayText() {
    let translatedText = this.intl.t(this.settings.messengerAutomaticResponseTimeTranslationKey, {
      locale: this.intl.locale,
    });
    return this.appService.app.canUseOfficeHoursImprovements
      ? translatedText
      : `"${translatedText}"`;
  }

  get showReportingSettings() {
    if (this.schedule.isDefault) {
      return this.app.inboxIsActive && !isEmpty(this.schedule.timeIntervals);
    }

    return false;
  }

  get selectedResponseDelay() {
    let { customResponseDelayEnabled, customResponseDelay } = this.settings;
    return customResponseDelayEnabled ? customResponseDelay : this.automaticResponseDelay;
  }

  get schedule() {
    return this.args.schedule;
  }

  get settings() {
    return this.args.settings;
  }

  @action
  changeSelectedResponseDelay(selection) {
    let settings = this.settings;
    let selectedResponseDelay = parseInt(selection, 10);
    if (selectedResponseDelay === this.automaticResponseDelay) {
      settings.setProperties({
        customResponseDelayEnabled: false,
        //Even though it's not used when ART is enabled, we set customResponseDelay back to the original value so that "fake" changes to the model aren't detected and the Save/Cancel buttons are shown
        customResponseDelay: this.settings.data.customResponseDelay,
      });
    } else {
      settings.setProperties({
        customResponseDelayEnabled: true,
        customResponseDelay: selectedResponseDelay,
      });
    }
    if (this.appService.app.canUseOfficeHoursImprovements) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'office_hours_reply_time',
        value: selection,
      });
    }
  }

  @action
  setCustomResponseDelay(delay) {
    this.settings.setProperties({
      customResponseDelayValue: delay,
    });
  }

  get timeZoneOptions() {
    let currentDate = new Date();

    let timeZones = IntercomTimezoneOptions.map((item) => {
      try {
        let formatter = new Intl.DateTimeFormat(this.intl.primaryLocale, {
          timeZone: item.value,
          timeZoneName: 'longOffset',
        });

        let parts = formatter.formatToParts(currentDate);
        let part = parts.find((p) => p.type === 'timeZoneName');

        return {
          value: item.value,
          text: `(${part.value}) ${item.name}`,
        };
      } catch (_e) {
        return {
          value: item.value,
          text: item.description,
        };
      }
    });

    if (this.schedule.timeZoneName) {
      let scheduleTimeZoneInList = timeZones.find(
        ({ value }) => value === this.schedule.timeZoneName,
      );

      if (!scheduleTimeZoneInList) {
        return [
          ...timeZones,
          {
            value: this.schedule.timeZoneName,
            text: this.schedule.timeZoneName,
          },
        ];
      }
    }

    return timeZones.uniqBy('value');
  }
}
