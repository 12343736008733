/* import __COLOCATED_TEMPLATE__ from './fin-content-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';

export type BannerContent = {
  heading: string;
  description: string;
  dismissalKey: string;
  helpLink?: { text: string; url: string };
};

interface FinContentBannerArgs {
  bannerContent: BannerContent;
  finContentStatus?: string;
  email?: string;
  containerClass?: string;
}

const FinContentBanner = templateOnlyComponent<FinContentBannerArgs>();
export default FinContentBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::FinContentBanner': typeof FinContentBanner;
  }
}
