/* RESPONSIBLE TEAM: team-ai-chatbot */

export function resolutionBotBillingFeature(app: any): string {
  if (app.canUseFinExperience) {
    if (app.hasAnswerBot) {
      return 'answer_workflows';
    } else {
      return 'ai_answers_billing_feature';
    }
  } else {
    return 'answer_workflows';
  }
}

export function hasResolutionBot(app: any): boolean {
  if (resolutionBotBillingFeature(app) === 'answer_workflows') {
    return app.hasAnswerBot;
  }

  return app.canUseFinExperience;
}
