/* import __COLOCATED_TEMPLATE__ from './domains.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';

interface Args {}

export default class Domains extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: Router;

  @tracked showAddCustomSenderModal = false;
  @tracked openSectionId: number | null = null;

  get app() {
    return this.appService.app;
  }

  get emailAddressHash() {
    let addressHash = new Map();
    this.app.customEmailAddresses.forEach((address: $TSFixMe) => {
      let domain = address.email
        .substring((address.email.lastIndexOf('@') as number) + 1)
        .toLowerCase();

      if (!addressHash.has(domain)) {
        addressHash.set(domain, []);
      }
      addressHash.get(domain).push({
        email: address.email,
        verified: address.verified,
        id: address.id,
      });
    });

    return addressHash;
  }

  get hydratedDomains() {
    let emailAddressHash = this.emailAddressHash;
    let domains: $TSFixMe[] = [];

    this.app.dkim_settings.forEach((record: $TSFixMe) => {
      let customBounceSetting = this.app.customBounceSettings.find((bounceSetting: $TSFixMe) => {
        return bounceSetting.rootDomain === record.domain;
      });

      let authenticated =
        record.validRecordExists &&
        customBounceSetting.validRecordExists &&
        record.validDmarcExists;

      domains.push({
        authenticated,
        dkimRecord: record,
        customBounceSetting,
        customAddresses: emailAddressHash.get(record.domain.toLowerCase()),
      });
    });
    return domains;
  }

  get allDomainsAuthenticated() {
    return this.hydratedDomains.every(
      (domain) =>
        domain.dkimRecord.validRecordExists && domain.customBounceSetting.validRecordExists,
    );
  }

  get allDomainsUnauthenticated() {
    return this.hydratedDomains.every(
      (domain) =>
        !domain.dkimRecord.validRecordExists || !domain.customBounceSetting.validRecordExists,
    );
  }

  @action
  onOpenSectionChange(newOpenSectionId: number | null) {
    this.openSectionId = newOpenSectionId;
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_settings_page',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Domains': typeof Domains;
  }
}
