/* import __COLOCATED_TEMPLATE__ from './switch.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class Switch extends Component {
  @service declare appService: any;

  @tracked selectedValue = 'setup';

  @action
  onSelectionChange(value: string) {
    this.selectedValue = value;
  }

  get paywallActiveOverride() {
    return !this.appService.app?.canUseFeature('switch');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Switch': typeof Switch;
  }
}
