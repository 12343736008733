/* import __COLOCATED_TEMPLATE__ from './replying-to-closed-email-convos.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { enqueueTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface Args {
  settings: $TSFixMe;
}

const DEFAULT_DURATION_DAYS = '5';

export default class ReplyingToClosedEmailConvos extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked displayDuration: string | null = this.getDurationInDays(
    this.args.settings.duration,
  ) as unknown as string;

  get isEnabled(): boolean {
    return this.displayDuration !== null;
  }

  @enqueueTask
  *updateSetting(): TaskGenerator<void> {
    if (this.displayDuration === '' || !this.displayDuration) {
      this.displayDuration = '0';
    } else if (isNaN(Number(this.displayDuration))) {
      this.displayDuration = DEFAULT_DURATION_DAYS;
    } else {
      this.displayDuration = this.displayDuration.replace(/[^0-9]/g, '');
    }

    try {
      this.args.settings.set('duration', this.getDurationInSeconds(`${this.displayDuration}`));
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleSettings() {
    if (this.displayDuration || parseInt(`${this.displayDuration}`, 10) >= 0) {
      this.displayDuration = null;
    } else {
      this.displayDuration = DEFAULT_DURATION_DAYS;
    }
    taskFor(this.updateSetting).perform();
  }

  getDurationInDays(duration: string) {
    if (duration || parseInt(duration, 10) === 0) {
      return parseInt(duration, 10) / (3600 * 24);
    } else {
      return null;
    }
  }

  getDurationInSeconds(duration: string) {
    if (duration || parseInt(duration, 10) === 0) {
      return parseInt(duration, 10) * 3600 * 24;
    } else {
      return null;
    }
  }

  handleKeyPress(event: KeyboardEvent) {
    if (!(event.key >= '0' && event.key <= '9')) {
      event.preventDefault();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::ReplyingToClosedEmailConvos': typeof ReplyingToClosedEmailConvos;
  }
}
