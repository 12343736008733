/* import __COLOCATED_TEMPLATE__ from './new-address-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type { Brand } from './types/brand';
import type { SenderEmailAddressSettings } from './types/sender-email-address-settings';
import type { SenderEmailAddressWireFormat } from './types/sender-email-address';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { postRequest } from 'embercom/lib/inbox/requests';
import { isValidEmail } from 'embercom/lib/email';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  brand: Brand;
  onCreateNewAddress: (senderEmailAddressSettings: SenderEmailAddressSettings) => void;
}

interface SenderEmailResponseFormat {
  email: string;
  id: string;
}

interface Signature {
  Args: Args;
  Element: never;
}

interface SenderEmailsRequestParams {
  app_id: string;
  admin_id: string;
  name: string;
  email: string;
  brand_id?: number;
}

export default class NewAddressModal extends Component<Signature> {
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked email = '';

  @action
  handleClose() {
    this.args.onClose();
  }

  @dropTask
  *createTask(): TaskGenerator<void> {
    let wireFormat;
    let params: SenderEmailsRequestParams = {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      name: this.args.brand.name,
      email: this.email,
    };

    if (!this.args.brand.isDefault) {
      params['brand_id'] = this.args.brand.id;
    }
    try {
      let response = yield postRequest('/ember/sender_emails', params);
      wireFormat = yield response.json() as SenderEmailAddressWireFormat;
      let returnedResponse = wireFormat.find(
        (item: SenderEmailResponseFormat) => item.email === this.email,
      );
      this.args.onCreateNewAddress({
        id: returnedResponse.sender_email_address_settings_id,
        email: returnedResponse.email,
        brandId: this.args.brand.isDefault ? undefined : this.args.brand.id,
      });
      this.handleClose();
    } catch (error) {
      console.error('createTask() error', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'new-settings.workspace.brands.new-address-modal.failed-to-create-address',
        ),
      });
      return;
    }
  }

  @action
  handleCreate() {
    taskFor(this.createTask).perform();
  }

  get isCreating() {
    return taskFor(this.createTask).isRunning;
  }

  get canCreate(): boolean {
    return Boolean(this.isReady && !this.isCreating);
  }

  get isReady() {
    return this.email && isValidEmail(this.email);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::NewAddressModal': typeof NewAddressModal;
  }
}
