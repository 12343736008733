/* import __COLOCATED_TEMPLATE__ from './submenu-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { IN_OPERATOR } from 'embercom/lib/outbound/constants';

export default class SubmenuComponent extends Component {
  @service router;
  @service appService;
  @service outboundHomeService;
  @service intercomEventService;

  @tracked showSavedViews = true;
  @tracked showSettings = false;

  constructor() {
    super(...arguments);
    this.showSettings = this.isOnSettingsRoute;
  }

  get app() {
    return this.appService.app;
  }

  get linkQuery() {
    return {
      selectedObjectTypes: null,
      state: null,
      senderId: null,
      tagIds: null,
      subscriptionTypeId: null,
      searchString: null,
      hideSeries: null,
      audiences: null,
      tagOperator: IN_OPERATOR,
    };
  }

  get isOnSeriesRoute() {
    return /^apps\.app\.outbound\.series/.test(this.router.currentRouteName);
  }

  get isOnSettingsRoute() {
    return (
      /^apps\.app\.outbound\.settings/.test(this.router.currentRouteName) ||
      /^apps\.app\.outbound\.news\.newsfeeds/.test(this.router.currentRouteName) ||
      /^apps\.app\.outbound\.news\.labels/.test(this.router.currentRouteName)
    );
  }

  get activeList() {
    return this.outboundHomeService.activeList;
  }

  get objectTypeConstants() {
    return objectTypes;
  }

  @action
  trackStartGuideEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'start_guide_clicked',
      context: 'from_messages_submenu',
      place: 'start_guide',
    });
  }

  @action
  toggleShowSavedViews() {
    this.showSavedViews = !this.showSavedViews;
  }
}
