/* import __COLOCATED_TEMPLATE__ from './phone-number-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';

export default class PhoneNumberSelector extends Component {
  @service appService;
  @service notificationsService;
  @service store;
  @service intl;

  @tracked availablePhoneNumbers = [];
  @tracked selectedNumber = null;

  @action
  onSelectionChange(value) {
    this.selectedNumber = value;
  }

  @action
  refreshNumbers() {
    this.fetchAvailablePhoneNumbers.perform();
    this.selectedNumber = null;
  }

  @action
  confirmActivation() {
    this.purchaseSelectedPhoneNumber.perform();
  }

  @task({ drop: true })
  *fetchAvailablePhoneNumbers() {
    this.availablePhoneNumbers = yield fetch(
      `/ember/sms/fetch_available_numbers?app_id=${this.appService.app.id}&country_code=${this.args.selectedCountry.code}`,
    ).then((response) => {
      return response.json();
    });
  }

  @task({ drop: true })
  *purchaseSelectedPhoneNumber() {
    let phoneNumber = this.store.createRecord('sms/phone-number', { number: this.selectedNumber });

    let errorHandler = (e) => {
      this.store.unloadAll('sms/phone-number');
      this.notificationsService.notifyError(
        this.intl.t('outbound.sms.onboarding.select-number.phone-number-purchase-failed'),
      );
    };

    try {
      yield phoneNumber
        .save()
        .then((phoneNumber) => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('outbound.sms.onboarding.select-number.phone-number-purchase-succeed'),
          );
          if (this.args.updatePurchasedPhoneNumber) {
            this.args.updatePurchasedPhoneNumber(phoneNumber);
          }
          if (this.args.resetForm) {
            this.args.resetForm();
          }
        })
        .catch(errorHandler);
    } catch (e) {
      errorHandler.call;
    }
  }
}
