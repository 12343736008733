/* import __COLOCATED_TEMPLATE__ from './target-channels-text-cell.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CHANNEL_METADATA_MAPPING } from 'embercom/lib/operator/custom-bots/constants';

export default class TargetChannelsTextCell extends Component {
  @service intl;

  get localizedChannelList() {
    let targetChannels = this.args.wrapper.contents.firstObject.contentData.target_channels;
    return targetChannels.map((channel) => {
      return this.intl.t('operator.workflows.channels.type', {
        channel: CHANNEL_METADATA_MAPPING[channel].value,
      });
    });
  }

  get targetChannelList() {
    let localizedList = this.localizedChannelList;
    if (localizedList.length > 3) {
      let nMore = localizedList.length - 2;
      localizedList = [
        ...localizedList.slice(0, 2),
        this.intl.t('outbound.list.target-channels-cell.n-more', {
          remainingChannelCount: nMore,
        }),
      ];
    }
    return this.intl.formatList(localizedList, { type: 'conjunction' });
  }

  get toolTipChannelList() {
    return this.intl.formatList(this.localizedChannelList, { type: 'conjunction' });
  }
}
